import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from 'querystringify';
import Create from './Create';
import Update from './Edit';
import Delete from './Delete';
import { list, table } from '../../store/actions';
import Tables from '../../components/DynamicTables';

const columns = [
  {
    Header:'Judul / Nama',
    accessor: 'fullname'
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Provider',
    accessor: 'provider'
  },
  {
    Header: 'Address',
    accessor: 'address'
  },
  {
    Header: 'Phone',
    accessor: 'phone'
  },
  {
      Header: 'Verified At',
      accessor: 'email_verified_at'
  }
]

class Artikel extends Component {
  state = {
    isCreate: false,
    isUpdate: false,
    isDelete: false,
    originalData: []
  }

  loadModules = (param) => {
    const query = queryString.stringify(param)
    // this.props.list({
    //   name: 'users',
    //   customName: 'post',
    //   query
    // })
    this.props.list({
        name: 'users?type=general',
        customName: 'post',
        withType: '&',
        query
      })
  }

  componentDidMount() {
    const { query, table } = this.props
    if(query.page !== 1){
      table({ page: 1, keyword: '' })
    }else{
      table({ keyword: '' })
      this.loadModules({
        ...query,
        keyword: ''
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { query,data } = this.props
    // query.page + 1
    console.log(query)
    const page = query + 1
    if (prevProps.query !== this.props.query) {
      this.loadModules(query)
    }
  }

  // handleView = (data) => {
  //   const { history } = this.props
  //   const { uuid } = data.original
  //   history.push(`/post/${uuid}`)
  // }
  handleDelete = (data, isDelete) => {
    let originalData = data && data.original

    this.setState({
      isDelete,
      originalData: originalData
    })
  }
  handleCreate = (isCreate) => {
    this.setState({
      isCreate,
    })
  }
  handleUpdate = (data,isUpdate) => {
    let originalData = data && data.original

    this.setState({
      isUpdate,
      originalData: originalData
    })
  }
  render() {
    const { data } = this.props
    let result = (data && data.data.data && data.data.data) || []
    console.log(result)
    const { isCreate, isUpdate, isDelete, originalData } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Tables
              withFilter={{ placeHolder: 'Cari User'}}
              withSelectRow
              withPagination
              title="User"
              name="tasklist" // following the name or customName in this.props.list
              columns={columns}
              withCreate={(isCreate) => this.handleCreate(isCreate)}
              data={result}
              lastPage={data?.data?.last_page}
              totalData={data?.data.total}
              actions={[
                { label: 'Update', color: 'light', action: (data, isUpdate) => this.handleUpdate(data,isUpdate) },
                { label: 'Delete', color: 'danger', action: (data, isDelete) => this.handleDelete(data,isDelete) }
              ]}
            />
            <Create 
              isOpen={isCreate}
              onClose={()=> this.setState({ isCreate: false})}
            />
            <Update
              isOpen={isUpdate}
              onClose={() => this.setState({ isUpdate: false })}
              original={originalData}
            />
            <Delete
              isOpen={isDelete}
              onClose={() => this.setState({ isDelete: false })}
              original={originalData}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    data: state.module.list.post,
    query: state.module.table
  };
};

export default connect(mapStatetoProps, { list, table })(Artikel);
