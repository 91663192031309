import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import history from '../utils/history'

import Layout from "./layout/reducer";
import Global from './global/reducer';
import Auth from "./auth/reducer";
import Module from "./module/reducer";

const rootReducer = combineReducers({
  router: connectRouter(history),
  global: Global,
  layout: Layout,
  auth: Auth,
  module: Module
});

export default rootReducer;
