import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from 'querystringify';
import Create from './Create';
import Update from './Edit';
import Delete from './Delete';
import { list, table } from '../../store/actions';
import Tables from '../../components/DynamicTables';
import Axios from 'axios';
import { getUser } from '../../utils/localstorage';
import { URL_API } from "../../shared/constants";

const columns = [
  {
    Header:'Nama Rumah Sakit',
    accessor: 'name'
  },
  {
    Header: 'Provinsi',
    accessor: 'province_name'
  },
  {
    Header: 'Kota ',
    accessor: 'regency_name'
  },
  {
    Header: 'Status',
    accessor:'facility_name'
  },
  {
    Header: 'Pemeriksaan',
    accessor:'inspection_name'
  },
  {
    Header: 'URL Google MAP',
    accessor:'location'
  },
  // {
  //   Header: 'Status',
  //   accessor: 'status'
  // }
]

class Artikel extends Component {
  constructor(props){
    super(props)
    this.state = {
      isCreate: false,
      isUpdate: false,
      isDelete: false,
      originalData: [],
      listProvinsi: [],
    }
    this.baseUrl = process.env.REACT_APP_DOMAIN_API
  }

  loadModules = (param) => {
    const { directoryParams } = this.props
    const query = {
      page: param?.page || 1, 
      keyword: param?.keyword || '',
      inspections: directoryParams?.inspections
    }
    this.props.list({
      name: 'directories',
      customName: 'post',
      query: queryString.stringify(query)
    })
  }

  componentDidMount() {
    const { query, table } = this.props
    this.loadProvinsi()
    if(query.page !== 1){
      table({ page: 1, keyword: '' })
    }else{
      table({ keyword: '' })
      this.loadModules({
        page: query.page,
        keyword: ''
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { query,data } = this.props
    // query.page + 1
    const page = query + 1
    if (prevProps.query !== this.props.query) {
      this.loadModules(query)
    }
  }

  loadProvinsi = () => {
    const auth = getUser()
    Axios({
      method: 'GET',
      url: `${URL_API}api/provinces`,
      headers: {
        "Authorization": `Bearer ${auth.token}`
      }
    })
    .then(res => {
			console.log(res.data)
			this.setState({ listProvinsi: res.data.data })
		})
  }

  // handleView = (data) => {
  //   const { history } = this.props
  //   const { uuid } = data.original
  //   history.push(`/post/${uuid}`)
  // }
  handleDelete = (data, isDelete) => {
    let originalData = data && data.original

    this.setState({
      isDelete,
      originalData: originalData
    })
  }
  handleCreate = (isCreate) => {
    this.setState({
      isCreate,
    })
  }
  handleUpdate = (data,isUpdate) => {
    let originalData = data && data.original

    this.setState({
      isUpdate,
      originalData: originalData
    })
  }
  render() {
    const { data } = this.props
    const { listProvinsi } = this.state
    let result = (data && data.data.data && data.data.data) || []
    const { isCreate, isUpdate, isDelete, originalData } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Tables
              withFilter={{ placeHolder: 'Cari Direktori'}}
              withSelectRow
              withPagination
              title="Direktori"
              name="tasklist" // following the name or customName in this.props.list
              columns={columns}
              lastPage={data?.data?.last_page}
              totalData={data?.data.total}
              withCreate={(isCreate) => this.handleCreate(isCreate)}
              data={result}
              actions={[
                { label: 'Update', color: 'light', action: (data, isUpdate) => this.handleUpdate(data,isUpdate) },
                { label: 'Delete', color: 'danger', action: (data, isDelete) => this.handleDelete(data,isDelete) }
              ]}
            />
            <Create 
              isOpen={isCreate}
              listProvinsi={listProvinsi}
              onClose={()=> this.setState({ isCreate: false})}
            />
            <Update
              isOpen={isUpdate}
              listProvinsi={listProvinsi}
              onClose={() => this.setState({ isUpdate: false })}
              original={originalData}
            />
            <Delete
              isOpen={isDelete}
              onClose={() => this.setState({ isDelete: false })}
              original={originalData}
              typeContent="directory"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    data: state.module.list.post,
    query: state.module.table,
    directoryParams: state.module.directoryParams
  };
};

export default connect(mapStatetoProps, { list, table })(Artikel);
