import React, { Component } from 'react';
import { connect } from 'react-redux';
import { add } from '../../store/actions';

class UploadFile extends Component {

  onUpload = (files) => {
    this.props.add({
      name: '',
      data: {
        files
      }
    })
  }

  handleChange = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        loading: true,
      });
      this.onUpload({
        file: reader.result,
        name: file.name,
        size: file.size,
        type: file.type
      })
    };
    reader.readAsDataURL(file)
  }

  render () {
    const { title, name, required } = this.props;
    return (
      <div className="custom-file">
        <input 
          type="file" 
          className="custom-file-input" 
          id="upload"
          name={name}
          onChange={this.handleChange}
          required={required} 
        />
        <label className="custom-file-label" htmlFor="customFile">
          {title || 'Choose file'}
        </label>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
  }
}

export default connect(mapStateToProps, { add })(UploadFile);