import React, { Component } from "react";
import Axios from 'axios';
import { Modal, Spinner, Table } from "reactstrap";
import moment from 'moment';
import { connect } from "react-redux";
import { add, list } from '../../store/actions';
import TextField from '../../components/Form/TextField';
import DatePicker from '../../components/Form/DatePicker';
import SelectForm from "../../components/Form/SelectForm";
import withFormik from '../../utils/withFormik';
import UploadFile from '../../components/Form/FormikUploadFile';
import { getUser } from '../../utils/localstorage';
import { URL_API } from "../../shared/constants";
import queryString from 'querystringify';

// import schemeValidations from './schemeValidationPfic';

const initialValueForm = {
  uuid: '',
  title: '',
  description: '',
  start_at: '',
  image_url: '',
  thumbnail: ''
}

const mapPropsToValues = ({ original }) => {
  const event = original
  if (event) {
    return {
      uuid: event.uuid,
      title: event.title,
      description: event.description,
      start_at: event.start_at,
      image_url: event.image_url,
      thumbnail: event.thumbnail,
      status: event.status
    }
  }
  return initialValueForm
}

class EditEvent extends Component {
  constructor() {
    super();
    this.state = {
      file: '',
      loading: false,
      gotResponse: false,
      // baseUrl: process.env.REACT_APP_DOMAIN_API,
      baseUrl: URL_API
    }

    this.fileSelect = this.fileSelect.bind(this);
  }

  fileSelect(e) {
    this.setState({ file: e.target.files[0] })
  }

  loadModules = (param) => {
    const query = queryString.stringify(param)
    this.props.list({
      name: 'forum/events',
      customName: 'post',
      query
    })
  }


  handleEdit = () => {
    const { title, description, start_at, content, tags, status, uuid, type } = this.props.values
    console.log(this.state.file)
    console.log(title)
    this.setState({ loading: true })
    const formData = new FormData();
    formData.append('title', title);
    formData.append('start_at', start_at);
    formData.append('thumbnail', this.state.file)
    formData.append('description', description);
    // formData.append('status', status);
    const auth = getUser()
    const post = Axios.post(`${this.state.baseUrl}api/forum/events/${uuid}?_method=put`, formData,
      { headers: { "Content-type": `multipart/form-data;boundary=${formData._boundary}`, "Authorization": `Bearer ${auth.token}` } }
    ).then(res => {
      console.log(res)
      if (res.status === 204) {
        this.setState({ loading: false, gotResponse: true })
      }
    })
    console.log(post)

  }
  componentDidUpdate(prevProps, prevState) {
    const { resetForm, onClose } = this.props
    if (prevState.gotResponse !== this.state.gotResponse) {
      const { param } = this.props
      const query = queryString.stringify(param)
      this.loadModules(query)
      resetForm()
      onClose()
    }
  }

  render() {
    const {
      data,
      route,
      isOpen,
      onClose,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      setFieldValue,
      handleSubmit,
      resetForm } = this.props;
    const { loading } = this.state
    return (
      <Modal
        size="xl"
        isOpen={isOpen}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Event</h5>
          <button
            type="button"
            onClick={() => { resetForm(); onClose() }}
            className="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form className="form-horizontal mt-4">
          <div className="modal-body">
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Judul *</label>
              <div className="col-sm-8">
                <TextField
                  name="title"
                  required
                  value={values.title}
                  placeholder="Masukan Judul"
                  className="form-control"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.title && errors.title}
                />
              </div>
              <label className="col-sm-4 mt-2 col-form-label">Waktu Mulai *</label>
              <div className="col-sm-8 mt-2">
                <TextField
                  name="start_at"
                  required
                  value={values.start_at}
                  className="form-control"
                  type="datetime"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.start_at && errors.start_at}
                />
              </div>

              <label className="col-sm-4 col-form-label">Description *</label>
              <div className="col-sm-8 mt-2">
                <TextField
                  name="description"
                  required
                  value={values.description}
                  placeholder="Masukan Description"
                  className="form-control"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && errors.description}
                />
              </div>

              <label className="col-sm-4 col-form-label">Thumbnail *</label>
              <div className="col-sm-8 mt-2">
                {/* <input type="file" onChange={this.fileSelect} /> */}
                {
                  values.image_url ? <img src={values.image_url} style={{ width: '100%', maxWidth: 200 }} /> : false
                }
              </div>
              {/* <label className="col-sm-4 col-form-label">Status *</label>
              <div className="col-sm-8 mt-2">
                <SelectForm
                  name="status"
                  required
                  type="select"
                  value={values.status}
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.status && errors.status}
                >
                  <option value=''>Pilih Status</option>
                  <option value="publish">
                    publish
									</option>
                  <option value="private">
                    private
									</option>
                </SelectForm>
              </div> */}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => { resetForm(); onClose() }}
            >
              Batal
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.handleEdit()}
            >
              {/* Simpan */}
              {loading ? <Spinner size="sm" color="light" /> : 'Simpan'}
            </button>
          </div>
        </form>
      </Modal>
    )
  }
}

const mapStatetoProps = state => {
  return {
    data: state.module.list.type,
    param: state.module.table,
    // loading: state.global.loading,
    gotResponse: state.global.gotResponse,
  };
};

const EditEventFormik = withFormik(
  EditEvent,
  null,
  mapPropsToValues, null
)


export default connect(mapStatetoProps, { add, list })(EditEventFormik);
