import React, { Component } from "react";
import { Table, Modal, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { remove, list } from '../../store/actions';
import queryString from 'querystringify';

class DeleteReason extends Component {

  componentDidUpdate(prevProps) {
    if (prevProps.gotResponse !== this.props.gotResponse) {
			const { param } = this.props
			const query = queryString.stringify(param)
			this.loadModules(query)
      this.props.onClose()
    }
  }

	loadModules = (param) => {
		const query = queryString.stringify(param)
		this.props.list({
			name: 'forum/events',
			customName: 'post',
			query
		})
	}

  handleDelete = (uuid) => {
    this.props.remove({
      name: 'forum/events',
      id: uuid,
      customMessage: 'Hapus Event berhasil'
    })
  }

  render() {
    const { isOpen, loading, onClose, original } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Hapus Alasan</h5>
          <button
            type="button"
            onClick={onClose}
            className="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>Apakah Anda yakin menghapus Event ini?</p>
          <Table bordered>
            <tbody>
              <tr>
                <td><strong>Judul</strong></td>
                <td>{original.title}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onClose}
          >
            Batal
          </button>
          <button
            onClick={() => this.handleDelete(original.uuid)}
            type="submit"
            className="btn btn-primary"
          >
            {loading ? <Spinner size="sm" color="light" /> : 'Ya'}
          </button>
        </div>
      </Modal>
    )
  }
}

const mapStatetoProps = state => {
  return {
    loading: state.global.loading,
		param: state.module.table,
    gotResponse: state.global.gotResponse
  };
};

export default connect(mapStatetoProps, { remove, list })(DeleteReason);