import React, { Component } from 'react';
import { connect } from 'react-redux';

import { logout, resetStore } from '../../store/actions';
import { withRouter } from 'react-router-dom';

class Logout extends Component {
  /**
   * Redirect to login
   */
  componentDidMount = () => {
    // emit the event
    this.props.resetStore()
    this.props.logout(this.props.history);
  };

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default withRouter(connect(null, { logout, resetStore })(Logout));
