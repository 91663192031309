import Immutable from 'seamless-immutable';

const FAILURE = 'FAILURE';
const SIGN_IN = 'SIGN_IN';
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
const SIGN_UP = 'SIGN_UP';
const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
const RESET_PASSWORD = 'RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_PROFILE = 'CHANGE_PASSWORD_PROFILE';
const CHANGE_PASSWORD_PROFILE_SUCCESS = 'CHANGE_PASSWORD_PROFILE_SUCCESS';
const ACTIVATION = 'ACTIVATION';
const LOGOUT = 'LOGOUT';

const actionTypes = Immutable({
  FAILURE,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_PROFILE,
  CHANGE_PASSWORD_PROFILE_SUCCESS,
  ACTIVATION,
  LOGOUT
}); 

export default actionTypes;
