import Immutable from 'seamless-immutable';
import actionTypes from './actionTypes';

export const INITIAL_STATE = Immutable({
  user: null,
  error: null,
});

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.SIGN_IN_SUCCESS:
      return state.merge({ user: action.data });
    case actionTypes.FORGOT_PASSWORD_SUCCESS: 
      return state.merge({ user: action.data });
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return state.merge({ user: action.data });
    case actionTypes.CHANGE_PASSWORD_PROFILE_SUCCESS:
      return state.merge({ user: action.data });
    case actionTypes.LOGOUT: 
      return state.merge({ user: action.data });
    case actionTypes.FAILURE:
      return state.merge({ error: action.error });
    default:
      return state;
  }
}

export default reducer;
