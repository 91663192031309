import React, { Component } from "react";
import Axios from 'axios';
import { Modal, Spinner, Table } from "reactstrap";
import moment from 'moment';
import { connect } from "react-redux";
import { add, list } from '../../store/actions';
import TextField from '../../components/Form/TextField';
import SelectForm from "../../components/Form/SelectForm";
import withFormik from '../../utils/withFormik';
import { URL_API } from "../../shared/constants";
import { getUser } from '../../utils/localstorage';
import responseValidatorArr from "../../shared/responseValidatorArr";

// import schemeValidations from './schemeValidationPfic';

const initialValueForm = {
	name: '',
	province_id: '',
	regency_id: '',
	location: '',
	facility: '',
}

class CreateArticle extends Component {
	constructor() {
		super();
		this.state = {
			file: '',
			loading: false,
			gotResponse: false,
			baseUrl: process.env.REACT_APP_DOMAIN_API,
			selectedPemeriksaan: [],
			selectedFacilities: [],
			error: {}
		}

		this.fileSelect = this.fileSelect.bind(this);
		this.listOption = ['Mata', 'Telinga', 'Jantung', 'Tiroid', 'Kepala', 'Anemia']
	}
	fileSelect(e) {
		this.setState({ file: e.target.files[0] })
	}


	handleCreate = () => {
		const { selectedPemeriksaan, selectedFacilities, file } = this.state
		const { 
			name,
			province_id,
			regency_id,
			location,
		 } = this.props.values
		console.log(this.state.file)
		this.setState({loading: true})
		const formData = new FormData();
		formData.append('province_id', province_id);
		formData.append('regency_id', regency_id);
		formData.append('location', location);
		formData.append('name', name);
		if(file){
			formData.append('thumbnail', file)
		}
		selectedFacilities.forEach(facility => {
			formData.append('facilities[]', facility);
		})
		selectedPemeriksaan.forEach(inspect => {
			formData.append('inspections[]', inspect);
		})

		const auth = getUser()
		const post = Axios.post(`${URL_API}api/directories`, formData,
			{ headers: { "Content-type": `multipart/form-data;boundary=${formData._boundary}`, "Authorization": `Bearer ${auth.token}` } }
		).then(res => {
			console.log(res.data)
			if(res.data.status === 201){
				this.setState({ loading : false, gotResponse: true})
				this.props.list({
					name: 'directories',
					customName: 'post',
				})
			}
		}).catch(err => {
			if(err?.response?.data?.errors){
				this.setState({ error: responseValidatorArr(err.response.data.errors) })
			}
			this.setState({ loading : false})
		})

	}
	  componentDidUpdate(prevProps, prevState) {
     const { resetForm, onClose } = this.props
     if (prevState.gotResponse !== this.state.gotResponse) {
         resetForm()
         onClose()
		 this.setState({ error: {}, gotResponse: false })
     }
    }

	checkSelectedPemeriksaan = (name) => {
		const { selectedPemeriksaan } = this.state
		if(selectedPemeriksaan.includes(name)){
			return true
		}
		return false
	}

	handleCheked = (event) => {
		const dataName = event.target.name
		if(event.target.checked){
			const listData = [...this.state.selectedPemeriksaan]
			listData.push(dataName)
			this.setState({selectedPemeriksaan: listData})
		}else{
			this.setState(prevState => ({ selectedPemeriksaan: prevState.selectedPemeriksaan.filter(item => item !== dataName )}))
		}
	}

	checkSelectedFacilities = (name) => {
		const { selectedFacilities } = this.state
		if(selectedFacilities.includes(name)){
			return true
		}
		return false
	}

	handleChekedFacilities = (event) => {
		const dataName = event.target.name
		if(event.target.checked){
			const listData = [...this.state.selectedFacilities]
			listData.push(dataName)
			this.setState({selectedFacilities: listData})
		}else{
			this.setState(prevState => ({ selectedFacilities: prevState.selectedFacilities.filter(item => item !== dataName )}))
		}
	}

	getProvinceRegencies = () => {
		const { values, listProvinsi } = this.props
		if(values?.province_id){
			const findRegencies = listProvinsi.find(item => item.id === Number(values.province_id))
			if(findRegencies){
				return findRegencies.regencies
			}
		}
		return []
	}

	render() {
		const {
			isOpen,
			onClose,
			values,
			touched,
			errors,
			handleChange,
			handleBlur,
			resetForm,
			listProvinsi
		} = this.props;
			const { loading, error } = this.state
			console.log("CHECK VALUE :", values)
		return (
			<Modal
				size="xl"
				isOpen={isOpen}
				centered={true}
			>
				<div className="modal-header">
					<h5 className="modal-title mt-0">Tambah Direktori</h5>
					<button
						type="button"
						onClick={() => { resetForm(); onClose() }}
						className="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form className="form-horizontal mt-4">
					<div className="modal-body">
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Nama Rumah Sakit*</label>
							<div className="col-sm-8">
								<TextField
									name="name"
									required
									value={values.name}
									placeholder="Masukan Nama Rumah Sakit"
									className="form-control"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={error?.name}
								/>
							</div>
							<label className="col-sm-4 col-form-label">URL Google MAP*</label>
							<div className="col-sm-8 mt-2">
								<TextField
									name="location"
									required
									value={values.location}
									placeholder="Masukan URL Google MAP"
									className="form-control"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={error?.location}
								/>
							</div>
							<label className="col-sm-4 col-form-label">Provinsi *</label>
							<div className="col-sm-8 mt-2">
								<SelectForm
									name="province_id"
									required
									type="select"
									value={values.province_id}
									className="form-control form-select"
									onChange={handleChange}
									onBlur={handleBlur}
									error={error?.province_id}
								>
									<option value=''>Pilih Provinsi</option>
									{listProvinsi.map(province => (
										<option key={province.id} value={province.id}>{province.name}</option>
									))}
									
								</SelectForm>
							</div>
							<label className="col-sm-4 col-form-label">Kota *</label>
							<div className="col-sm-8 mt-2">
								<SelectForm
									name="regency_id"
									required
									type="select"
									value={values.regency_id}
									className="form-control form-select"
									onChange={handleChange}
									onBlur={handleBlur}
									error={error?.regency_id}
								>
									<option value=''>Pilih Kota</option>
									{this.getProvinceRegencies().map(regencies => (
										<option value={regencies.id} key={regencies.id}>{regencies.name}</option>
									))}
									
								</SelectForm>
							</div>
							<label className="col-sm-4 col-form-label">Status Rumah Sakit</label>
							<div className="col-sm-8 mt-2">
								{['BPJS', 'Swasta'].map(item => (
									<div className="ctn-checkbox">
										<input type="checkbox" className="checkbox-default" name={item} onChange={this.handleChekedFacilities} checked={this.checkSelectedFacilities(item)} />
										<label className="label-checkbox">{item}</label><br />
									</div>
								))}
								{error?.inspections &&
									<span class="error-text">{error?.facilities}</span>
								}
							</div>
							<label className="col-sm-4 col-form-label">Layanan Pemeriksaan</label>
							<div className="col-sm-8 mt-2">
								{this.listOption.map(item => (
									<div className="ctn-checkbox">
										<input type="checkbox" className="checkbox-default" name={item} onChange={this.handleCheked} checked={this.checkSelectedPemeriksaan(item)} />
										<label className="label-checkbox">{item}</label><br />
									</div>
								))}
								{error?.inspections &&
									<span class="error-text">{error?.inspections}</span>
								}
							</div>
							{/* <div className="col-sm-4 mt-2">
								<label className="col-form-label">Thumbnail</label>
								<br />
								<span className="label-description">(Ukuran gambar yang disarankan adalah 525px x 225px atau  735px x 315px.)</span>
							</div>
							
							<div className="col-sm-8 mt-2">
								<input type="file" onChange={this.fileSelect} />
							</div> */}
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => { resetForm(); onClose() }}
						>
							Batal
            </button>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.handleCreate()}
						>
							{/* Simpan */}
              {loading ? <Spinner size="sm" color="light" /> : 'Simpan'}
						</button>
					</div>
				</form>
			</Modal>
		)
	}
}

const mapStatetoProps = state => {
	return {
		data: state.module.list.type,
		// loading: state.global.loading,
		gotResponse: state.global.gotResponse,
	};
};

const CreateArticleFormik = withFormik(
	CreateArticle,
	null,
	initialValueForm, null
)


export default connect(mapStatetoProps, { add, list })(CreateArticleFormik);
