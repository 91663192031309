import Immutable from 'seamless-immutable';
import actionTypes from './actionTypes';

const INITIAL_STATE = Immutable({
  loading: false,
  dataCaptcha: null,
  loadingTable: false,
  confirmForm: false,
  gotResponse: false,
  notif: {
    open: false,
    variant: 'success',
    message: 'SUCCESS',
    socket: false
  }
})

const global = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return state.merge({ loading: action.param })
    case actionTypes.LOADING_TABLE:
      return state.merge({ loadingTable: action.param })
    case actionTypes.CAPTCHA: 
      return state.merge({ dataCaptcha: action.data })
    case actionTypes.NOTIF:
      return state.merge({ loading: false, notif: action.data })
    case actionTypes.CONFIRM_FORM:
      return state.merge({ confirmForm: action.param })
    case actionTypes.NOTIF_CLOSE:
      return state.merge({
        loading: false,
        notif: {
          open: false,
          variant: state.notif.variant,
          message: state.notif.message,
          socket: state.notif.socket
        }
      })
    case actionTypes.GOT_RESPONSE:
      return state.merge({ gotResponse: action.param })

    default:
      return state
  }
}

export default global
