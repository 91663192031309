import React from 'react';

const TextField = ({
  type,
  icon,
  error,
  value,
  onChange,
  className,
  ...props
}) => {
  return (
    <div className="form-group">
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <div className="input-group-text">{icon}</div>
        </div>
        <input
          className={!error ? className : 'form-control is-invalid'}
          type={type}
          value={value}
          onChange={onChange}
          {...props}
        />
        {error &&
          <div className="invalid-feedback">
            {error}
          </div>
        }
      </div>
    </div>
  )
}

TextField.defaultProps = {
  type: 'text',
  error: '',
  value: '',
  className: '',
};

export default TextField;