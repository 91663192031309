import React, { Component } from "react";
import Axios from 'axios';
import { Modal, Spinner, Table } from "reactstrap";
import moment from 'moment';
import { connect } from "react-redux";
import { add, list } from '../../store/actions';
import TextField from '../../components/Form/TextField';
import DatePicker from '../../components/Form/DatePicker';
import SelectForm from "../../components/Form/SelectForm";
import withFormik from '../../utils/withFormik';
import UploadFile from '../../components/Form/FormikUploadFile';
import { getUser } from '../../utils/localstorage';

// import schemeValidations from './schemeValidationPfic';

const initialValueForm = {
	title: '',
	description: '',
	status:'',
	phone:'',
	password:''
}

// const handleFormSubmit = (payload, ctx) => {
// 	const { title, desc, thumbnail, content, tags } = payload
// 	// ctx.props.add({
// 	// 	name: `posts`,
// 	// 	data: {
// 	// 		type: 'article',
// 	// 		title: title,
// 	// 		description: desc,
// 	// 		  thumbnail: thumbnail,
// 	// 		content: content,
// 	// 		tags: tags,
// 	// 		parent_id: 1,
// 	// 	},
// 	// });
// 	// Axios.post()
// }

class CreateArticle extends Component {
	constructor() {
		super();
		this.state = {
			file: '',
			loading: false,
			gotResponse: false,
			baseUrl: process.env.REACT_APP_DOMAIN_API
		}

		this.fileSelect = this.fileSelect.bind(this);
	}
	fileSelect(e) {
		this.setState({ file: e.target.files[0] })
	}


	handleCreate = () => {
		const { title, description, city, tags, status, type, parent_id,phone,password } = this.props.values
		console.log(status)
		this.setState({loading: true})
		const formData = new FormData();
		formData.append('fullname', title);
		// formData.append('thumbnail', this.state.file)
		formData.append('email', description);
		formData.append('address', status);
		formData.append('phone', phone);
		formData.append('password', password);


		const auth = getUser()
		const post = Axios.post(`${this.state.baseUrl}api/users`, formData,
			{ headers: { "Content-type": `multipart/form-data;boundary=${formData._boundary}`, "Authorization": `Bearer ${auth.token}` } }
		).then(res => {
			console.log(res.data)
			if(res.data.status === 201){
				this.setState({ loading : false, gotResponse: true})
			}
		})
		console.log(post)

	}
	  componentDidUpdate(prevProps, prevState) {
     const { resetForm, onClose } = this.props
     if (prevState.gotResponse !== this.state.gotResponse) {
         resetForm()
         onClose()
     }
    }

	render() {
		const {
			data,
			route,
			isOpen,
			onClose,
			values,
			touched,
			errors,
			handleChange,
			handleBlur,
			setFieldValue,
			handleSubmit,
			resetForm } = this.props;
			const {loading } = this.state
		return (
			<Modal
				size="xl"
				isOpen={isOpen}
				centered={true}
			>
				<div className="modal-header">
					<h5 className="modal-title mt-0">Tambah User</h5>
					<button
						type="button"
						onClick={() => { resetForm(); onClose() }}
						className="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form className="form-horizontal mt-4">
					<div className="modal-body">
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Fullname *</label>
							<div className="col-sm-8">
								<TextField
									name="title"
									required
									value={values.title}
									placeholder="Masukan Nama"
									className="form-control"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.title && errors.title}
								/>
							</div>
							<label className="col-sm-4 col-form-label">Email *</label>
							<div className="col-sm-8 mt-2">
								<TextField
									name="description"
									required
									value={values.description}
									placeholder="Masukan Email"
									className="form-control"
									type="email"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.description && errors.description}
								/>
							</div>
							<label className="col-sm-4 col-form-label">Password *</label>
							<div className="col-sm-8 mt-2">
								<TextField
									name="password"
									required
									value={values.password}
									placeholder="Masukan password"
									className="form-control"
									type="password"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.password && errors.password}
								/>
							</div>
							{/* <label className="col-sm-4 col-form-label">Thumbnail *</label>
							<div className="col-sm-8 mt-2">
								<input type="file" onChange={this.fileSelect} />
							</div> */}
							<label className="col-sm-4 col-form-label">Address *</label>
							<div className="col-sm-8 mt-2">
							<TextField
									name="status"
									required
									value={values.status}
									placeholder="Masukan Address"
									className="form-control"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.status && errors.status}
								/>
								{/* <SelectForm
									name="status"
									required
									type="select"
									value={values.status}
									className="form-control"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.status && errors.status}
								>
									<option value=''>Pilih Status</option>
									<option value="publish">
										publish
									</option>
									<option  value="private">
										private
									</option>
								</SelectForm> */}
							</div>
							<label className="col-sm-4 col-form-label">Phone *</label>
							<div className="col-sm-8 mt-2">
								<TextField
									name="phone"
									required
									value={values.phone}
									placeholder="Masukan Phone"
									className="form-control"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.phone && errors.phone}
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => { resetForm(); onClose() }}
						>
							Batal
            </button>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.handleCreate()}
						>
							{/* Simpan */}
              {loading ? <Spinner size="sm" color="light" /> : 'Simpan'}
						</button>
					</div>
				</form>
			</Modal>
		)
	}
}

const mapStatetoProps = state => {
	return {
		data: state.module.list.type,
		// loading: state.global.loading,
		gotResponse: state.global.gotResponse,
	};
};

const CreateArticleFormik = withFormik(
	CreateArticle,
	null,
	initialValueForm, null
)


export default connect(mapStatetoProps, { add })(CreateArticleFormik);
