import React from 'react';
import { FormGroup } from "reactstrap";
import { useAsyncDebounce } from 'react-table';
import { connect } from "react-redux";
import { table } from '../../store/actions'

const GlobalFilter = ({ className, ...props }) => {
  const [value, setValue] = React.useState(props.remarks)
  const onChange = useAsyncDebounce(value => {
    props.table({ ...props.query, keyword: value })
  }, 200)

  return (
    <FormGroup className={className}>
      <input
        defaultValue={value}
        className="form-control"
        type="search"
        placeholder={props.placeHolder ? props.placeHolder : "Cari di sini"}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </FormGroup>
  )
}

const mapStatetoProps = state => {
  return {
    query: state.module.table
  };
};

export default connect(mapStatetoProps, { table })(GlobalFilter);