import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import history from './utils/history'

// Import Routes
import App from './App';
// State Global
import store from './store';
// For multi language
import './i18n';
// Import scss
import "./assets/scss/theme.scss";

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <BrowserRouter>
        <Router history={history}>
          <App />
        </Router>
      </BrowserRouter>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
