
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

//i18n
import { withNamespaces } from 'react-i18next';

// users
import user1 from '../../../assets/images/users/avatar-1.jpg';

import { getUser } from '../../../utils/localstorage';
// import { accessByRole } from '../../../utils/accessRight';

class ProfileMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      username: ''
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }

  componentDidMount() {
    this.setState({ 
      username: 'admin'
    }) 
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
          <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
            <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" />
            <span className="d-none d-xl-inline-block ml-2 mr-1">{this.state.username}</span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu right>
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
              <span>{this.props.t('Logout')}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withRouter(withNamespaces()(ProfileMenu));
