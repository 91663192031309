import React, { Component } from 'react';
import { ToastContainer } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import IdleTimer from 'react-idle-timer';
import Routes from './routes';
import history from './utils/history';
import { destroyUser, getUser } from './utils/localstorage';
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  constructor(props) {
    super(props)
    this.idleTime = null
    this.state = {
      isTimeOut: false,
      remaining: 10
    }
  }

  onIdle = () => {
    this.setState({
      isTimeOut: true,
    })
    if (getUser()) {
      setTimeout(() => {
        if (!this.state.isTimeOut) {
          this.idleTime.resume()
        } else  {
          this.confirmLogout();
        }
      }, 10000)
    }
  }

  keepStay = () => {
    this.idleTime.reset()
    this.setState({
      isTimeOut: false
    })
  }

  confirmLogout = () => {
    this.setState({
      isTimeOut: false
    })
    destroyUser()
    history.push('/sign-in');
  }

  render() {
    const { isTimeOut, remaining } = this.state
    return (
      <IdleTimer
        ref={ref => { this.idleTime = ref }}
        timeout={15 * (60 * 1000)}
        onIdle={this.onIdle}
      >
        <Routes />
        <ToastContainer
          autoClose={4000}
          position="top-center"
        />
        {isTimeOut && getUser() &&
          <SweetAlert
            showCancel
            confirmBtnText="Stay Connected"
            cancelBtnText="Logout"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            title="Your Session is About to Expire!"
            onCancel={() => this.confirmLogout()}
            onConfirm={() => this.keepStay()}
          >
            Redirecting in {remaining} seconds<br></br>
          </SweetAlert>
        }
      </IdleTimer>
    )
  }
}

export default App;
