import React, { Component } from 'react';
import {
  Row,
  Col,
} from 'reactstrap';
import { connect } from "react-redux";
import { table } from '../../store/actions';

import Pagination from 'react-bootstrap-4-pagination';

const calculateRange = (arg) => {
  const { current, pageSize } = arg
  let total =10
  let end = 10
  let start = 1
  if (pageSize < end) {
    let beforeNumber = Math.round(pageSize / 2 - 0.5)
    const afterNumber = beforeNumber
    if (pageSize % 2 === 0) {
      beforeNumber -= 1
    }

    if (current <= beforeNumber + 1) {
      end = pageSize
    } else if (current >= total - afterNumber) {
      start = total - pageSize + 1
    } else {
      start = current - beforeNumber
      end = current + afterNumber
    }
  }

  return { end, start }
}

const getStateFromProps = (props) => {
  let {  current, pageSize } = props
  let total = 10
  current = current > -1 ? current : 1
  pageSize = pageSize > 0 ? pageSize : 1
  current = current < total ? current : total
  pageSize = pageSize < total ? pageSize : total
  return { current, pageSize, total }
}

class PaginationTable extends Component {
  constructor (props) {
    super(props)
    const temp = getStateFromProps(props)

    this.state = {
      ...temp,
      ...calculateRange(temp),
    }
  }

  getlistPage = (arr) => {
    const { totalPage } = this.props
    if(totalPage){
      return totalPage
    }
    return arr
  }

  static getDerivedStateFromProps(props, state) {
    const temp = getStateFromProps(props)
    return {
      ...temp,
      ...calculateRange(temp)
    }
  }

  setCurrent (current) {
    console.log("CHECK STATE CURRENT :", current)
    this.props.table({ ...this.props.query, page: current })
    this.setState({
      current: current || 1
    })
  }

  
  render() {
    const { pageSize, query } = this.props
    const pageConfig = {
      totalPages: pageSize,
      currentPage: query?.page,
      showMax: pageSize > 8 ? 8 : pageSize,
      // size: "lg",
      threeDots: false,
      prevNext: true,
      onClick: (page) => {
        this.props.table({ ...this.props.query, page })
      }
    }
    return (
      <Row className="mt-4">
        <Col lg="12">
          <div className="App">
            <Pagination {...pageConfig} />
          </div>
        </Col>
      </Row>
    )
  }
}

const mapStatetoProps = state => {
  return {
    data: state.module.list,
    query: state.module.table
  };
};

export default connect(mapStatetoProps, { table })(PaginationTable);