import React, { Component } from 'react';
import * as Yup from 'yup';
import withFormik from '../../utils/withFormik';
import { Row, Col, CardBody, Card, Container, Spinner, Button } from "reactstrap";
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// actions
import { changePasswordProfile } from '../../store/actions';
import Premies from "../../assets/images/logo-sm-light.png";
import TextField from './Components/TextField';

const initialValueForm = {
  newPassword: '',
  confirmationPassword: ''
}

const schemeValidations = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, 'Password baru minimal 6 karakter')
    .required('Password baru tidak boleh kosong'),
  confirmationPassword: Yup.string()
    .required('Konfirmasi password tidak boleh kosong')
    .min(6, 'Minimum length of password is 6 characters')
    .oneOf([Yup.ref('newPassword'), null], "Password baru dan konfirmasi password harus sama")
})

const handleFormSubmit = (payload, ctx) => {
  const { newPassword, confirmationPassword } = payload
  const body = {
    newPassword,
    confirmationPassword,
    oldPassword: 'premies'
  }
  ctx.props.changePasswordProfile({
    data: body,
    isAfterLogin: true
  });
  ctx.resetForm()
}

class ChangePassword extends Component {

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      loading } = this.props;
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={6} lg={4} xl={4}>
                <Card className="shadow-lg overflow-hidden py-4">
                  <CardBody className="pt-0">
                    <div className="card-body text-center">
                      <Link to="/">
                        <div>
                          <img src={Premies} alt="Premies" className="img-fluid mx-auto" />
                        </div>
                      </Link>
                      <h4 className="mt-3">
                        Change Password
                      </h4>
                    </div>
                    <p className="text-center">Anda belum mengubah password default. Silakan mengubah password untuk keamanan Anda!</p>
                    <form className="px-2">
                      <TextField
                        icon={<i className="bx bx-lock-alt" />}
                        name="newPassword"
                        type="password"
                        className="form-control"
                        placeholder="Password Baru"
                        value={values.newPassword}
                        onChange={handleChange}
                        onKeyDown={(e) => { e.keyCode === 13 && handleSubmit(e); }}
                        onBlur={handleBlur}
                        error={touched.newPassword && errors.newPassword}
                      />
                      <TextField
                        icon={<i className="bx bx-lock-alt" />}
                        name="confirmationPassword"
                        type="password"
                        className="form-control"
                        placeholder="Konfirmasi Password Baru"
                        value={values.confirmationPassword}
                        onChange={handleChange}
                        onKeyDown={(e) => { e.keyCode === 13 && handleSubmit(e); }}
                        onBlur={handleBlur}
                        error={touched.confirmationPassword && errors.confirmationPassword}
                      />
                      <div className="mt-3">
                        <Button
                          color="primary"
                          className="btn-block"
                          onClick={handleSubmit}
                        >
                          {loading ? <Spinner size="sm" color="light" /> : 'Change Password'}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { loading } = state.global;
  return { loading };
}

const FormikChangePassword = withFormik(
  ChangePassword,
  schemeValidations,
  initialValueForm,
  handleFormSubmit
)

export default withRouter(connect(mapStateToProps, { changePasswordProfile })(FormikChangePassword))

