import React, { Component } from "react";
import Axios from 'axios';
import { Modal, Spinner, Table } from "reactstrap";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { connect } from "react-redux";
import { add, list } from '../../store/actions';
import TextField from '../../components/Form/TextField';
import SelectForm from "../../components/Form/SelectForm";
import withFormik from '../../utils/withFormik';
import { getUser } from '../../utils/localstorage';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { URL_API } from "../../shared/constants";

const initialValueForm = {
	parent_id:'',
	type: '',
	title: '',
	description: '',
	scope: '',
	thumbnail: '',
	content: '',
	tags: '',
	status: '',
} 

// const handleFormSubmit = (payload, ctx) => {
// 	const { title, desc, thumbnail, content, tags } = payload
// 	// ctx.props.add({
// 	// 	name: `posts`,
// 	// 	data: {
// 	// 		type: 'article',
// 	// 		title: title,
// 	// 		description: desc,
// 	// 		  thumbnail: thumbnail,
// 	// 		content: content,
// 	// 		tags: tags,
// 	// 		parent_id: 1,
// 	// 	},
// 	// });
// 	// Axios.post()
// }

class CreateArticle extends Component {
	constructor() {
		super();
		this.state = {
			file: '',
			loading: false,
			gotResponse: false,
			// baseUrl: process.env.REACT_APP_DOMAIN_API,
			baseUrl: URL_API,
            editorState: EditorState.createEmpty(),
			title: '',
			type: '',
			content: '',
			tags: '',
			description: '',
			scope: '',
			isEdit: false,
			listUploadedPhoto: [],
			loadingUploaded: false
		}

		this.fileSelect = this.fileSelect.bind(this);
	}

	// componentWillReceiveProps(newProps){
	// 	const { isOpen } = this.props
	// 	if(!isOpen && newProps.isOpen && newProps.initialData){
	// 		console.log("OPEN MODAL", newProps.initialData)
	// 		if(newProps.initialData){
	// 			this.handleInitData(newProps.initialData)
	// 		}
	// 	}
	// }
	handleInitData = (original) => {
		if(original){
    		const contentBlock = htmlToDraft(original.content);
			this.setState({
				title: original.title,
				type: original.type,
				content: original.content,
				tags: original.tags,
				description: original.description,
				scope: original.scope,
				isEdit: original.id
			})
			if(contentBlock){
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      			const editorState = EditorState.createWithContent(contentState);
				this.setState({ editorState })
			}
		}
	}
	fileSelect(e) {
		this.setState({ file: e.target.files[0] })
	}

	selectInArticlePhoto = (e) => {
		this.setState({ loadingUploaded: true })
		const photos = e.target.files[0]
		let apiURL = `${this.state.baseUrl}api/media`
		const formData = new FormData();
		formData.append('url[]', photos);
		// formData.append('status', status);
		const auth = getUser()
		Axios.post(apiURL, formData,
			{ headers: { "Content-type": `multipart/form-data;boundary=${formData._boundary}`, "Authorization": `Bearer ${auth.token}` } }
		).then(res => {
			console.log("CHECK PHOTO:", res)
			this.setState(prevState => ({ loadingUploaded: false, listUploadedPhoto: [...prevState.listUploadedPhoto, ...res.data.data] }))
		})
		.catch(err => {
			this.setState({ loadingUploaded: false })
			console.log("Err upload photo :", err)
		})

	}

	handleCopyToast = () => {
		toast('URL berhasil disalin!', {
			position: "top-right",
			bodyStyle: { color: '#000'},
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			});
	}

	handleCreate = () => {
		const { title, description, scope, tags, type, isEdit } = this.state
		const { list, onClose } = this.props
		let apiURL = `${this.state.baseUrl}api/posts`
		if(isEdit){
			apiURL = `${this.state.baseUrl}api/posts/${isEdit}?_method=put`
		}
        const content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
		this.setState({loading: true})
		const formData = new FormData();
		formData.append('title', title);
		formData.append('type', type);
		formData.append('content', content);
		formData.append('tags', tags);
		formData.append('parent_id', 1);
		formData.append('thumbnail', this.state.file);
		formData.append('description', description);
		formData.append('scope', scope);
		// formData.append('status', status);
		const auth = getUser()
		const post = Axios.post(apiURL, formData,
			{ headers: { "Content-type": `multipart/form-data;boundary=${formData._boundary}`, "Authorization": `Bearer ${auth.token}` } }
		).then(res => {
			console.log(res.data)
			if(res.data.status === 201){
				if(typeof list === 'function') list({
					name: 'posts',
					customName: 'post',
					query: 'page=1'
				  })
				this.setState({
					loading : false,
					gotResponse: true, 
					editorState: EditorState.createEmpty(),
					title: '',
					type: '',
					content: '',
					tags: '',
					description: '',
					scope: '',
					isEdit: false,
					listUploadedPhoto: []
				})
         		onClose()
			}
		})
		.catch(err => {
			alert('Harap lengkapi semua data.')
			this.setState({ loading : false})
		})

	}

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState
        });
    }

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	renderlistPhoto(){
		const { listUploadedPhoto } = this.state
		if(listUploadedPhoto.length > 0){
			return listUploadedPhoto.map(item => (
				<li class="list-group-item d-flex justify-content-between align-items-center" key={item.id}>
					<CopyToClipboard onCopy={this.handleCopyToast} text={item.path_url}>
						<div className="button-click">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
								<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
								<path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
							</svg>
							<span className="copy-text">Salin URL </span>
						</div>
					</CopyToClipboard>
					<div class="image-parent">
						<img src={item.path_url} class="img-fluid" alt="quixote" />
					</div>
				</li>
			))
		}
		return null
	}

	render() {
		const {
			data,
			route,
			isOpen,
			onClose,
			values,
			touched,
			errors,
			handleChange,
			handleBlur,
			setFieldValue,
			handleSubmit,
			resetForm } = this.props;
			const { loading, title, description, scope, tags, type } = this.state
		return (
			<Modal
				size="xl"
				isOpen={isOpen}
				centered={true}
			>
				<div className="modal-header">
					<div className="column-wrapper">
					<h5 className="modal-title mt-0">Tambah Artikel</h5>
					<span className="txt-desc">Catatan: satuan yang digunakan untuk gambar yaitu "px".  1cm sama dengan 37px, untuk kalkulator konversi dari cm ke px dapat klik <a className="link-click" href="https://www.unitconverters.net/typography/centimeter-to-pixel-x.htm" target="_blank">disini.</a></span>
					</div>
					<button
						type="button"
						onClick={() => { resetForm(); onClose() }}
						className="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form className="form-horizontal mt-4">
					<div className="modal-body">
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Judul *</label>
							<div className="col-sm-8">
								<TextField
									name="title"
									required
									value={title}
									placeholder="Masukkan Judul"
									className="form-control"
									type="text"
									onChange={this.handleChange}
									error={touched.title && errors.title}
								/>
							</div>
							<label className="col-sm-4 col-form-label">Content *</label>
							<div className="col-sm-8 mt-2">
                                <Editor
                                    editorState={this.state.editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
									editorStyle={{ color:'#000', lineHeight: '24px'}}
									handlePastedText={() => false}
                                    onEditorStateChange={this.onEditorStateChange}
                                />
							</div>
							<label className="col-sm-4 col-form-label">Upload Foto Artikel *</label>
							<div className="col-sm-8 mt-2">
								<input type="file" onChange={this.selectInArticlePhoto} />
								<div className="mt-2">
									<label className="col-form-label">Foto yang diupload: </label>
									{this.state.loadingUploaded && <span className="txt-spinner"><Spinner size="sm" color="dark" /> loading...</span>}
									<ul class="list-group">
        								{this.renderlistPhoto()}
									</ul>
								</div>
							</div>
							<label className="col-sm-4 col-form-label mt-4">Description *</label>
							<div className="col-sm-8 mt-4">
								<TextField
									name="description"
									required
									value={description}
									placeholder="Masukkan Description"
									className="form-control"
									type="text"
									onChange={this.handleChange}
									error={touched.description && errors.description}
								/>
							</div>
							<label className="col-sm-4 col-form-label">Scope *</label>
							<div className="col-sm-8 mt-2">
								<SelectForm
									name="scope"
									required
									type="select"
									value={scope}
									className="form-control"
									onChange={this.handleChange}
									error={touched.scope && errors.scope}
								>
									<option value=''>Pilih Scope</option>
									<option value="general">
										Orang Tua
									</option>
									<option value="doctor">
										Dokter
									</option>
								</SelectForm>
							</div>
							<label className="col-sm-4 col-form-label">Tags *</label>
							<div className="col-sm-8 mt-2">
								<TextField
									name="tags"
									required
									value={tags}
									placeholder="Masukkan Tags "
									className="form-control"
									type="text"
									onChange={this.handleChange}
									error={touched.tags && errors.tags}
								/>
							</div>
							<label className="col-sm-4 col-form-label">Thumbnail *</label>
							<div className="col-sm-8 mt-2">
								<input type="file" onChange={this.fileSelect} />
							</div>
							{/* <label className="col-sm-4 col-form-label">Categories *</label>
							<div className="col-sm-8 mt-2">
								<SelectForm
									name="parent_id"
									required
									type="select"
									value={values.parent_id}
									className="form-control"
									onChange={handleChange}
									error={touched.parent_id && errors.parent_id}
								>
									<option value=''>Pilih Categories</option>
									<option value="1">
										Artikel
									</option>
									<option  value="2">
										Daftar masalah
									</option>
									<option value="3">
										Edukasi keluarga
									</option>
									<option value="4">
										Newborn
									</option>
								</SelectForm>
							</div> */}
							<label className="col-sm-4 col-form-label">Type *</label>
							{scope === 'doctor' ? (
							<div className="col-sm-8 mt-2">
								<SelectForm
									name="type"
									required
									type="select"
									value={type}
									className="form-control"
									onChange={this.handleChange}
									error={touched.type && errors.type}
								>
									<option value=''>Pilih Type</option>
									<option value="nutrisi">
										Nutrisi
									</option>
									<option  value="grafik">
										Grafik Pertumbuhan
									</option>
									<option value="screening">
										Skrining Kesehatan
									</option>
								</SelectForm>
							</div> 
							) : (
								<div className="col-sm-8 mt-2">
									<SelectForm
										name="type"
										required
										type="select"
										value={type}
										className="form-control"
										onChange={this.handleChange}
									>
										<option value=''>Pilih Type</option>
										<option value="article">
											Artikel Kesehatan{/* Article */}
										</option>
										<option  value="problem">
											Ceklis{/* Problem */}
										</option>
										<option value="family">
											Edukasi Keluarga{/* Family */}
										</option>
									</SelectForm>
								</div> 
							)
							}
							{/* <label className="col-sm-4 col-form-label">Status *</label>
							<div className="col-sm-8 mt-2">
								<SelectForm
									name="status"
									required
									type="select"
									value={values.status}
									className="form-control"
									onChange={handleChange}
									error={touched.status && errors.status}
								>
									<option value=''>Pilih Status</option>
									<option value="publish">
										publish
									</option>
									<option  value="private">
										private
									</option>
								</SelectForm>
							</div> */}
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => { resetForm(); onClose() }}
						>
							Batal
            </button>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.handleCreate()}
						>
							{/* Simpan */}
              {loading ? <Spinner size="sm" color="light" /> : 'Simpan'}
						</button>
					</div>
				</form>
			</Modal>
		)
	}
}

const mapStatetoProps = state => {
	return {
		data: state.module.list.type,
		// loading: state.global.loading,
		gotResponse: state.global.gotResponse,
	};
};

const CreateArticleFormik = withFormik(
	CreateArticle,
	null,
	initialValueForm, null
)


export default connect(mapStatetoProps, { add, list })(CreateArticleFormik);
