import Immutable from 'seamless-immutable';

const NOTIF = 'NOTIF';
const NOTIF_CLOSE = 'NOTIF_CLOSE';
const LOADING = 'LOADING';
const CAPTCHA = 'CAPTCHA';
const LOADING_TABLE = 'LOADING_TABLE';
const CONFIRM_FORM = 'CONFIRM_FORM';
const GOT_RESPONSE = 'GOT_RESPONSE';

const actionTypes = Immutable({
  NOTIF,
  NOTIF_CLOSE,
  LOADING,
  CAPTCHA,
  LOADING_TABLE,
  CONFIRM_FORM,
  GOT_RESPONSE
})

export default actionTypes