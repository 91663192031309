import React, { Component } from "react";
import { Table, Modal, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { remove } from '../../store/actions';

class DeleteReason extends Component {

  componentDidUpdate(prevProps) {
    if (prevProps.gotResponse !== this.props.gotResponse) {
      this.props.onClose()
    }
  }

  handleDelete = (uuid) => {
    this.props.remove({
      name: 'users',
      id: uuid,
      customMessage: 'Hapus Thread berhasil'
    })
  }

  render() {
    const { isOpen, loading, onClose, original } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Hapus Alasan</h5>
          <button
            type="button"
            onClick={onClose}
            className="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>Apakah Anda yakin menghapus Thread ini?</p>
          <Table bordered>
            <tbody>
              <tr>
                <td><strong>Email</strong></td>
                <td>{original.email}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onClose}
          >
            Batal
          </button>
          <button
            onClick={() => this.handleDelete(original.uuid)}
            type="submit"
            className="btn btn-primary"
          >
            {loading ? <Spinner size="sm" color="light" /> : 'Ya'}
          </button>
        </div>
      </Modal>
    )
  }
}

const mapStatetoProps = state => {
  return {
    loading: state.global.loading,
    gotResponse: state.global.gotResponse
  };
};

export default connect(mapStatetoProps, { remove })(DeleteReason);