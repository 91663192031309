import React from 'react';
import {
  FormFeedback,
  Input
} from 'reactstrap';

const SelectForm = ({
  type,
  label,
  error,
  value,
  onChange,
  className,
  ...props
}) => {
  return (
    <div>
      <Input
        className={className}
        type={type}
        value={value}
        onChange={onChange}
        invalid={error && error ? true : false}
        {...props}
      >
        {props.children}
      </Input>
      {error &&
        <FormFeedback>{error}</FormFeedback>
      }
    </div>
  )
}

SelectForm.defaultProps = {
  type: 'select',
  label: '',
  error: '',
  value: '',
  className: '',
};

export default SelectForm;