import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import queryString from 'querystringify';
import Create from './Create';
import Update from './Edit';
import Delete from './Delete';
import { list, table } from '../../store/actions';
import Tables from '../../components/DynamicTables';
import Approve from "./Approve";

const columns = [
  {
    Header:'Nama Lengkap',
    accessor: 'fullname'
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'NPA IDAI',
    accessor: 'code'
  },
  {
    Header: 'Kota',
    accessor: 'city'
  },
  {
      Header: 'Verified At',
      accessor: 'email_verified_at'
  },
  {
      Header: 'Approved At',
      accessor: 'activated_at'
  }
]

class Artikel extends Component {
  state = {
    isCreate: false,
    isUpdate: false,
    isApprove: false,
    isDelete: false,
    originalData: []
  }

  loadModules = (param) => {
    const query = queryString.stringify(param)
    this.props.list({
      name: 'users?type=doctor',
      customName: 'post',
      withType: '&',
      query
    })
  }

  componentDidMount() {
    const { query, table } = this.props
    if(query.page !== 1){
      table({ page: 1, keyword: '' })
    }else{
      table({ keyword: '' })
      this.loadModules({
        ...query,
        keyword: ''
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { query,data } = this.props
    // query.page + 1
    console.log(query)
    const page = query + 1
    if (prevProps.query !== this.props.query) {
      this.loadModules(query)
    }
  }

  refreshData = () => {
    const { query } = this.props
    this.loadModules(query)
  }

  // handleView = (data) => {
  //   const { history } = this.props
  //   const { uuid } = data.original
  //   history.push(`/post/${uuid}`)
  // }
  handleDelete = (data, isDelete) => {
    let originalData = data && data.original

    this.setState({
      isDelete,
      originalData: originalData
    })
  }
  handleCreate = (isCreate) => {
    this.setState({
      isCreate,
    })
  }
  handleUpdate = (data,isUpdate) => {
    let originalData = data && data.original

    this.setState({
      isUpdate,
      originalData: originalData
    })
  }
  handleApprove = (data,isApprove) => {
    let originalData = data && data.original
    this.setState({
      isApprove,
      originalData: originalData
    })
  }
  render() {
    const { data } = this.props
    let result = (data && data.data.data && data.data.data) || []
    console.log(result)
    const { isCreate, isUpdate, isApprove, isDelete, originalData, approved } = this.state;
    // const activated = result.map(function(r){
    //   return r.activated_at != null;
    // });
    const activated = result.map(r => r.activated_at != null);
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Tables
              withFilter={{ placeHolder: 'Cari Dokter'}}
              withSelectRow
              withPagination
              title="Dokter"
              name="tasklist" // following the name or customName in this.props.list
              columns={columns}
              withCreate={(isCreate) => this.handleCreate(isCreate)}
              data={result}
              actions={[
                // { label: 'Update', color: 'light', action: (data, isUpdate) => this.handleUpdate(data,isUpdate) },
                // { label: 'Approve', color: 'success',hidden : (result.activated_at != null) ? true : false, action: (data, isApprove) => this.handleApprove(data,isApprove) },
                { label: 'Approve', color: 'success',hidden : (data, isApprove) => data?.activated_at ? true : false, action: (data, isApprove) => this.handleApprove(data,isApprove) },
                { label: 'Delete', color: 'danger', action: (data, isDelete) => this.handleDelete(data,isDelete) }
              ]}
              lastPage={data?.data?.last_page}
              totalData={data?.data.total}
            />  
            <Create 
              isOpen={isCreate}
              onClose={()=> this.setState({ isCreate: false})}
            />
            {/* <Update
              isOpen={isUpdate}
              onClose={() => this.setState({ isUpdate: false })}
              original={originalData}
            /> */}
            <Approve
              isOpen={isApprove}
              onClose={() => this.setState({ isApprove: false })}
              original={originalData}
              onRefresh={this.refreshData}
            />
            <Delete
              isOpen={isDelete}
              onClose={() => this.setState({ isDelete: false })}
              original={originalData}
              onRefresh={this.refreshData}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    data: state.module.list.post,
    query: state.module.table
  };
};

export default connect(mapStatetoProps, { list, table })(Artikel);
