import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class NonAuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  capitalizeFirstLetter = string => {
    var split = string.split("/")
    var result = split[1].split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
    if (split.length > 2) {
      if (split[2] === ":id") {
        result = `${split[1].charAt(0).toUpperCase() + split[1].slice(1)} - Detail`
      } else {
        let child = split[2].split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
        result = `${split[1].charAt(0).toUpperCase() + split[1].slice(1)} - ${child}`
      }
    }
    return result
  };

  componentDidMount() {
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

    document.title =
      currentage + " | Pradini";
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props
    if (prevProps.match !== this.props.match) {
      if (match.params.id) {
        document.title = `${this.capitalizeFirstLetter(this.props.match.path)} | Pradini`
      } else {
        document.title = `${this.capitalizeFirstLetter(this.props.match.path)} | Pradini`
      }
    }
  }

  render() {
    return <React.Fragment>
      <div className="background-noauth">
        {this.props.children}
      </div>
    </React.Fragment>;
  }
}

export default withRouter(NonAuthLayout);