import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { getUser } from '../utils/localstorage';
// import { accessByPath } from '../utils/accessRight';
import VerticalLayout from "../components/VerticalLayout";
import NonAuthLayout from "../components/NonAuthLayout";

// pages
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangePwd from "../pages/Authentication/ChangePassword";

//artikel
import Artikel from '../pages/Artikel'

//directories
import Directories from '../pages/Directories'

// forum
import Forum from '../pages/Thread'

import User from '../pages/User'

import Dokter from "../pages/Dokter"

import PengaturanVersi from "../pages/PengaturanVersi"
import PengaturanMaintenance from "../pages/PengaturanMaintenance"
import ManajemenNotifikasi from "../pages/ManajemenNotifikasi"
import PengaturanLinkUpdate from "../pages/PengaturanLinkUpdate"

const Index = ({ childProps }) => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={'/artikel' || '/sign-in'} />} />
      <Route path="/logout" component={Logout} props={childProps} />
      <Route path="/error" component={Pages404} props={childProps} />
      <Route path="/pages-404" component={Pages404} props={childProps} />
      <Route path="/pages-500" component={Pages500} props={childProps} />
      <AuthRoute path='/artikel' component={Artikel} props={childProps} />
      <AuthRoute path='/directories' component={Directories} props={childProps} />
      <AuthRoute path='/forum' component={Forum} props={childProps} />
      <AuthRoute path='/user' component={User} props={childProps} />
      <AuthRoute path='/dokter' component={Dokter} props={childProps} />

      <AuthRoute path='/pengaturan-versi' component={PengaturanVersi} props={childProps} />
      <AuthRoute path='/manajemen-notifikasi' component={ManajemenNotifikasi} props={childProps} />
      <AuthRoute path='/pengaturan-maintenance' component={PengaturanMaintenance} props={childProps} />
      <AuthRoute path='/pengaturan-link-update' component={PengaturanLinkUpdate} props={childProps} />

      <ShouldRoute path="/sign-in" component={Login} props={childProps} />
      <ShouldRoute path="/forgot-password" component={ForgetPwd} props={childProps} />
      {/* <ShouldChangePwd path="/change-password" component={ChangePwd} props={childProps} /> */}
      <OtherRoute props={childProps} />
    </Switch>
  )
}

const OtherRoute = () => (<Route
  path="*"
  render={props => (
    getUser() ? (
      <Redirect
        to={{
          pathname: '/error',
          state: { from: props.location }
        }}
      />
    ) : (
      <Redirect
        to={{
          pathname: '/sign-in',
          state: { from: props.location }
        }}
      />
    )
  )}
/>)

const AuthRoute = ({ component: Component, ...rest }) => {
  const canAccess = true
  return (
    <Route
      {...rest}
      render={props => (
        getUser() ? (
          canAccess ?
            <VerticalLayout>
              <Component {...props} />
            </VerticalLayout>
            : <Redirect to={'/error'} />
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: props.location }
            }}
          />
        ))}
    />
  )
}

const ShouldRoute = ({ component: Component, location, ...rest }) => {
  const { from } = location.state || { from: { pathname: '/', state: { from: location } } }
  return (
    <Route
      {...rest}
      render={props => (
        !getUser() ? (
          <NonAuthLayout>
            <Component {...props} />
          </NonAuthLayout>
        ) : (
          <Redirect to={from} />
        )
      )}
    />
  )
}

const ShouldChangePwd = ({ component: Component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        getUser() ? (
          <NonAuthLayout>
            <Component {...props} />
          </NonAuthLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: props.location }
            }}
          />
        )
      )}
    />
  )
}

export default Index
