import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UploadFile from './UploadFile';

class FormikUploadFile extends Component {
  onFileChange = (val) => {
    this.props.onClickChange(this.props.name, val);
  }

  render() {
    const { onChange, ...otherProps } = this.props;
    return (
      <div>
        <UploadFile
          onChange={this.onFileChange}
          {...otherProps}
        />
      </div>
    );
  }
}

FormikUploadFile.propTypes = {
  name: PropTypes.string.isRequired,
  onFileChange: PropTypes.func.isRequired,
};

export default FormikUploadFile;
