import { all } from 'redux-saga/effects';

import AuthSaga from './auth/saga';
import LayoutSaga from './layout/saga';
import ModuleSaga from './module/saga';

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    LayoutSaga(),
    ModuleSaga()
  ])
}