import React, { Component } from "react";
import * as Yup from 'yup';
import withFormik from '../../utils/withFormik';
import { Row, Col, Card, CardBody, Container, Spinner, Button } from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// action
import { forgotPassword } from "../../store/actions";
// import images
import Premies from "../../assets/images/logo.png";
import TextField from './Components/TextField';

const initialValueForm = {
  email: ''
}

const schemeValidations = Yup.object().shape({
  email: Yup.string()
    .required('E-mail harus diisi')
    .email('Email tidak sesuai format')
})

const handleFormSubmit = (payload, ctx) => {
  const { email } = payload
  const body = {
    email
  }
  ctx.props.forgotPassword(body);
  ctx.resetForm()
}

class ForgetPasswordPage extends Component {

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      loading } = this.props;
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={6} lg={4} xl={4}>
                <Card className="shadow-lg overflow-hidden py-4">
                  <CardBody className="pt-0">
                    <div className="card-body text-center">
                      <Link to="/">
                        <div style={{ marginTop: '25px' }}>
                          <img src={Premies} alt="Premies" className="img-fluid mx-auto" />
                        </div>
                      </Link>
                      <h4 className="mt-3">
                        Forgot Password
                      </h4>
                    </div>
                    <form className="px-2">
                      <TextField
                        icon={<i className="bx bx-envelope" />}
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Alamat email"
                        value={values.email}
                        onChange={handleChange}
                        onKeyDown={(e) => { e.keyCode === 13 && handleSubmit(e); }}
                        onBlur={handleBlur}
                        error={touched.email && errors.email}
                      />
                      <Row className="form-group">
                        <Col className="text-right">
                          <Button
                            color="primary"
                            className="btn-block"
                            onClick={handleSubmit}
                          >
                            {loading ? <Spinner size="sm" color="light" /> : 'Kirim E-mail'}
                          </Button>
                        </Col>
                      </Row>
                      <div className="mt-4 text-center">
                        <Link to="/login" className="text-muted">Login</Link>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { loading } = state.global;
  return { loading };
};

const FormikForgetPassword = withFormik(
  ForgetPasswordPage,
  schemeValidations,
  initialValueForm,
  handleFormSubmit
)

export default withRouter(connect(mapStateToProps, { forgotPassword })(FormikForgetPassword))
