import actionTypes from './actionTypes';

export const failure = (error) => {
  return {
    type: actionTypes.FAILURE,
    error,
  };
}

export const signIn = (param, query) => {
  return {
    type: actionTypes.SIGN_IN,
    param,
    query
  };
}

export const signInSuccess = (data) => {
  return {
    type: actionTypes.SIGN_IN_SUCCESS,
    data,
  };
}

export const signUp = (param) => {
  return {
    type: actionTypes.SIGN_UP,
    param,
  };
}

export const signUpSuccess = (data) => {
  return {
    type: actionTypes.SIGN_UP_SUCCESS,
    data,
  };
}

export const forgotPassword = (param) => {
  return {
    type: actionTypes.FORGOT_PASSWORD,
    param,
  };
}

export const forgotPasswordSuccess = (data) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_SUCCESS,
    data,
  };
}

export const resetPassword = (param) => {
  return {
    type: actionTypes.RESET_PASSWORD,
    param,
  };
}

export const resetPasswordSuccess = (data) => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    data,
  };
}

export const changePasswordProfile = (param) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_PROFILE,
    param,
  };
}

export const changePasswordProfileSuccess = (data) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_PROFILE_SUCCESS,
    data,
  };
}

export const activation = (param) => {
  return {
    type: actionTypes.ACTIVATION,
    param,
  };
}

// export const activation = (param) => {
//   return {
//     type: actionTypes.ACTIVATION,
//     param,
//   };
// }

export const logout = (data) => {
  return {
    type: actionTypes.LOGOUT,
    data,
  };
}
