import React, { Component } from 'react';
import * as Yup from 'yup';
import withFormik from '../../utils/withFormik';
import { Row, Col, CardBody, Card, Container, Spinner, Button } from "reactstrap";
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// actions
import { signIn } from '../../store/actions';
// recaptcha
// import ReCAPTCHA from "react-google-recaptcha";
// import configRecaptcha from '../../utils/recaptcha';
// logo
import Premies from "../../assets/images/logo.png"
import TextField from './Components/TextField';

const initialValueForm = {
  email: '',
  password: '',
}

const schemeValidations = Yup.object().shape({
  email: Yup.string()
    .required('Username tidak boleh kosong'),
  password: Yup.string()
    .min(6, 'Password minimal 6 karakter')
    .required('Password tidak boleh kosong')
})

const handleFormSubmit = (payload, ctx) => {
  const { email, password } = payload
  const body = {
    email,
    password
  }
  ctx.props.signIn(body);
}

class Login extends Component {


  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      loading } = this.props;
    // const { clientLoad } = this.state;
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={6} lg={4} xl={4}>
                <Card className="shadow-lg overflow-hidden py-4">
                  <CardBody className="pt-0">
                    <div className="card-body text-center">
                      <Link to="/">
                        <div>
                          <img src={Premies} alt="Premies-BCA" className="img-fluid mx-auto" />
                        </div>
                      </Link>
                      <h4 className="mt-3">
                        Login
                      </h4>
                    </div>
                    <form className="p-2">
                      <TextField
                        icon={<i className="bx bx-user" />}
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        onKeyDown={(e) => { e.keyCode === 13 && handleSubmit(e); }}
                        onBlur={handleBlur}
                        error={touched.emauk && errors.email}
                      />
                      <TextField
                        icon={<i className="bx bx-lock-alt" />}
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                        onKeyDown={(e) => { e.keyCode === 13 && handleSubmit(e); }}
                        onBlur={handleBlur}
                        error={touched.password && errors.password}
                      />
                      <div className="mt-3">
                        <Button
                          color="primary"
                          className="btn-block"
                          onClick={handleSubmit}
                        >
                          {loading ? <Spinner size="sm" color="light" /> : 'Log In'}
                        </Button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot password?</Link>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { loading } = state.global
  return { loading };
};

const LoginFormik = withFormik(
  Login,
  schemeValidations,
  initialValueForm,
  handleFormSubmit
)

export default withRouter(connect(mapStateToProps, { signIn })(LoginFormik))

