import React from 'react';
import { FormGroup } from "reactstrap";
import { connect } from "react-redux";
import { table } from '../../store/actions'

const SelectPageSize = ({ className, ...props }) => {
  const { query } = props
  return (
    <FormGroup className={className}>
      <select
        className="form-control"
        value={query && query.size}
        onChange={e => {
          props.table({...props.query, size: Number(e.target.value)})
        }}
      >
        {[10, 25, 50, 100].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize} data
          </option>
        ))}
      </select>
    </FormGroup>
  )
}

const mapStatetoProps = state => {
  return {
    query: state.module.table
  };
};

export default connect(mapStatetoProps, { table })(SelectPageSize);