import React, { Component } from "react";
import Axios from 'axios';
import { Modal, Spinner, Table } from "reactstrap";
import moment from 'moment';
import { connect } from "react-redux";
import { add, list } from '../../store/actions';
import TextField from '../../components/Form/TextField';
import DatePicker from '../../components/Form/DatePicker';
import SelectForm from "../../components/Form/SelectForm";
import withFormik from '../../utils/withFormik';
import { getUser } from '../../utils/localstorage';
import { URL_API } from "../../shared/constants";
import { toast } from "react-toastify";


class PengaturanVersi extends Component {
  constructor() {
    super();
    this.state = {
      version: '',
      version: '',
      loading: false,
      baseUrl: process.env.REACT_APP_DOMAIN_API
    }
  }

  componentDidMount() {
    const auth = getUser()
    console.log(auth.token)
    Axios.get(`${URL_API}api/apps`,
      { headers: { "Authorization": `Bearer ${auth.token}` } }
    ).then(res => {
      console.log(res)
      if (res.status === 200) {
        this.setState({ loading: false })
        res.data.data.forEach(element => {
          if (element.name == 'version') {
            this.setState({ version: element.value })
          }
        });
      }
    })
  }

  handleSubmit = () => {
    const { version } = this.state
    this.setState({ loading: true })
    const formData = new FormData();
    formData.append('version', version);
    const auth = getUser()
    const post = Axios.post(`${URL_API}api/apps`, formData,
      { headers: { "Content-type": `multipart/form-data;boundary=${formData._boundary}`, "Authorization": `Bearer ${auth.token}` } }
    ).then(res => {
      if (res.status === 200) {
        this.setState({ loading: false })

        toast.success('Berhasil mengganti versi')
      }
    })
  }

  handleChange(val) {

    if (!val.target.value) {
      toast.warning('Harap isi versi')
    }

    this.setState({ version: val.target.value })
  }

  render() {
    const { loading, version } = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <h5 className="modal-title mt-0">Pengaturan Versi</h5>
                <form className="form-horizontal mt-4">
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Versi *</label>
                    <div className="col-sm-4">
                      <TextField
                        name="version"
                        required
                        value={version}
                        placeholder="Masukkan Versi"
                        className="form-control"
                        type="text"
                        onChange={(val) => this.handleChange(val)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label"></label>
                    <div className="col-sm-4">
                      <button
                        disabled={version ? false : true}
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.handleSubmit()}
                      >
                        {loading ? <Spinner size="sm" color="light" /> : 'Simpan'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    )
  }
}


export default PengaturanVersi;