import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Spinner,
  Button,
  CardTitle
} from 'reactstrap';
import {
  useTable,
  useSortBy,
  usePagination
} from 'react-table';
import GlobalFilter from './GlobalFilter';
import SelectPageSize from './SelectPageSize';
import PaginationTable from './PaginationTable';
import FilterContainer from '../FilterContainer';

const Tables = ({columns, data, lastPage = 0, ...props}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
    },
    useSortBy,
    usePagination
  )

  function getTotalPage(){
    if(lastPage){
      let arrPage = []
      for(let i = 1; i <= lastPage; i++) {
        arrPage.push(i)
      }
      return arrPage
    }
    return null
  }

  function renderFilter(){
    const { title } = props
    if(title === 'Artikel'){
      return (
        <div className="ctn-filter">
          {/* <FilterContainer dataType="article-category" placeholder="Pilih kategori..." /> */}
          <FilterContainer dataType="article-sort" placeholder="Urut berdasarkan..." />
        </div>
      )
    }
    if(title === 'Direktori'){
      return (
        <div className="ctn-filter">
          <FilterContainer dataType="directory-inspections" placeholder="Pilih pemeriksaan..." />
        </div>
      )
    }
  }


  function getArticleCategory(value){
    switch(value){
      case 'nutrisi':
        return 'Nutrisi';
      case 'grafik':
        return 'Grafik Pertumbuhan';
      case 'screening':
        return 'Skrining Kesehatan';
      case 'article':
        return 'Artikel';
      case 'problem':
        return 'Ceklis';
      case 'family':
        return 'Edukasi Keluarga';
      default:
        return value
    }
  }

  function getTextTotal(){
    if(props?.title === 'Direktori' && props.directoryParams?.inspections){
      return `*Total data Rumah Sakit layanan ${props.directoryParams?.inspections} adalah ${props?.totalData}`
    }
    return `*Total data ${props?.title || ''} adalah ${props?.totalData}`
  }

  function getItemTable(value, cellItem) {
    if(props.title === 'Artikel'){
      if(cellItem.column.id === 'type'){
        return getArticleCategory(cellItem.value)
      }
    }
    if(props.title === 'Direktori'){
      if(cellItem.column.id === 'location'){
        if(value.props.row.original.location){
          return <a href={value.props.row.original.location} target="_blank">Lokasi</a>
        }
        return '-'
      }
    }
    return value
  }

  const name = props.customName ? props.customName : props.name
  const payload = props.result[name] && props.result[name].payload
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Row>
              <Col md="7">
                <div className="d-flex align-items-center justify-content-start">
                {props.loading && <span className="mr-1 loader-mg"><Spinner size="sm" color="dark" /> .</span>}
                <div className="ctn-main-title">
                  {props.title &&
                    <CardTitle className="mr-3 title-mg">{props.title}</CardTitle>
                  }
                </div>
                  {renderFilter()}
                </div>
                {props?.totalData && !props.loading && (
                  <div className="d-flex align-items-center justify-content-start">
                    <span className="total-text">{getTextTotal()}</span>
                  </div>
                )}
              </Col>
              <Col md="5">
                <div className="float-right">
                  <form className="form-inline">
                    {/* {props.withSelectRow &&
                      <SelectPageSize className="mr-3" />
                    } */}
                    {props.withFilter &&
                      <GlobalFilter
                        className={props.withCreate ? "mr-3" : ""}
                        placeHolder={props.withFilter.placeHolder}
                      />
                    }
                    {props.withCreate &&
                      <Button
                        color="primary"
                        onClick={() => props.withCreate(true)}
                      >
                        Tambah
                      </Button>
                    }
                  </form>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col className="col-12">
                <div className="table-responsive">
                  <Table className="table table-striped mb-0" {...getTableProps()}>
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          <th>#</th>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                              <div>
                                <span {...column.getSortByToggleProps()}>
                                  {column.render('Header')}{' '}
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? <i className="fas fa-arrow-down"></i>
                                      : <i className="fas fa-arrow-up"></i>
                                    : ''
                                  }
                                </span>
                              </div>
                            </th>
                          ))}
                          {props.actions && props.actions.length
                            ? <th>Action</th>
                            : null
                          }
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.length > 0
                        ?
                        page.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              <td>{i + 1}</td>
                              {row.cells.map(cell => {
                                return (
                                  <td className={(cell.column.Header === 'Deskripsi' ? "limit-text" : "limit-text-2")} {...cell.getCellProps()} >
                                    {getItemTable(cell.render('Cell'), cell)}
                                  </td>
                                )
                              })}
                              <td className="text-nowrap ctn-btn-action">
                                {props.actions && props.actions.length && props.actions.map((n, idx) => {
                                  if(n.hidden && typeof n.hidden === 'function'){
                                    if(n.hidden(data[i])){
                                      return null
                                    }
                                  }
                                  return (
                                    n.specialCondition
                                      ?
                                        (n.specialCondition && n.specialCondition.status && n.specialCondition.status.key && n.specialCondition.status.value) &&
                                        (row.original[n.specialCondition.status.key] === n.specialCondition.status.value) && !n.specialCondition.isExclude
                                        ?
                                          <Button
                                            key={idx}
                                            color={n.color}
                                            className={idx === props.actions.length - 1 ? '' : 'mr-2'}
                                            onClick={() => n.action(row, true)}
                                            hidden={n.hidden}
                                          >
                                            {n.label}
                                          </Button>
                                        :
                                          (n.specialCondition && n.specialCondition.status && n.specialCondition.status.key && n.specialCondition.status.value) &&
                                          (row.original[n.specialCondition.status.key] !== n.specialCondition.status.value) && n.specialCondition.isExclude
                                        ?
                                          <Button
                                            key={idx}
                                            color={n.color}
                                            className={idx === props.actions.length - 1 ? '' : 'mr-2'}
                                            onClick={() => n.action(row, true)}
                                            hidden={n.hidden}
                                          >
                                            {n.label}
                                          </Button>
                                        :
                                          n.specialCondition && n.specialCondition.key && row.original[n.specialCondition.key] && !n.specialCondition.isExclude
                                        ?
                                          <Button
                                            key={idx}
                                            color={n.color}
                                            className={idx === props.actions.length - 1 ? '' : 'mr-2'}
                                            onClick={() => n.action(row, true)}
                                            hidden={n.hidden}
                                          >
                                            {n.label}
                                          </Button>
                                        :
                                          n.specialCondition && n.specialCondition.key && !row.original[n.specialCondition.key] && n.specialCondition.isExclude
                                        ?
                                          <Button
                                            key={idx}
                                            color={n.color}
                                            className={idx === props.actions.length - 1 ? '' : 'mr-2'}
                                            onClick={() => n.action(row, true)}
                                            hidden={n.hidden}
                                          >
                                            {n.label}
                                          </Button>
                                        : null
                                      :
                                        // default
                                        <Button
                                          key={idx}
                                          color={n.color}
                                          className={idx === props.actions.length - 1 ? '' : 'mr-2'}
                                          onClick={() => n.action(row, true)}
                                          hidden={n.hidden}
                                        >
                                          {n.label}
                                        </Button>
                                  )
                                })}
                              </td>
                            </tr>
                          )
                        })
                        :
                        <tr>
                          {!props.loading && (
                            <td colSpan={columns.length + 3} style={{ textAlign: 'center' }}>
                            Tidak ada data yang sesuai
                          </td>
                          )}
                        </tr>
                      }
                    </tbody>
                  </Table>
                  <div className="ctn-loader">
                    {props.loading && <span><Spinner size="sm" color="dark" /> loading...</span>}
                    {/* <span><Spinner size="sm" color="dark" /> loading...</span> */}
                  </div>
                </div>
              </Col>
            </Row>

            <PaginationTable
              name={name}
              total={lastPage ? lastPage : payload && payload.total_elements && Math.ceil(payload.total_elements / props.query.size)}
              current={payload && payload.current_page}
              pageSize={lastPage}
              totalPage={getTotalPage()}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

Tables.propTypes = {
  data: PropTypes.array,
  result: PropTypes.object
}

Tables.defaultProps = {
  data: []
}

const mapStateToProps = (state) => {
  return {
    result: state.module.list,
    loading: state.global.loadingTable,
    query: state.module.table,
    directoryParams: state.module.directoryParams
  }
}

export default connect(mapStateToProps)(Tables);