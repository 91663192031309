import React from 'react'
import Select from 'react-select'
import { articleCategory, sortDataArticle, directoryInspections } from './sortData'
import queryString from 'querystringify';
import states from './states'
import dispatcher from './dispatcher'
import { connect } from 'react-redux';

const dummyOptions = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]

const FilterContainer = ({ placeholder, dataType, setDirectoryParams, setArticleParams, articleParams }) => {

    function getOptionsData(){
        switch(dataType){
            case 'article-category':
                return articleCategory
            case 'article-sort':
                return sortDataArticle
            case 'directory-inspections':
                return directoryInspections
            default:
                return dummyOptions
        }
    }
    const handleChangedData = (item) => {
        switch(dataType){
            case 'article-category':
                // setArticleParams({
                //     ...articleParams,
                //     sortBy: item?.value || ''
                // })
                break;
            case 'article-sort':
                setArticleParams({
                    ...articleParams,
                    sortBy: item?.value || ''
                })
                break;
            case 'directory-inspections':
                setDirectoryParams({
                    inspections: item?.value || ''
                })
                break;
            default:
                break;
        }
    }

    return (
        <div className="filter-item">
            <Select
                isClearable
                options={getOptionsData()}
                onChange={handleChangedData}
                placeholder={placeholder} />
        </div>
    )    
}


export default connect(states, dispatcher)(FilterContainer)