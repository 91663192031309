import Immutable from 'seamless-immutable'
import actionTypes from './actionTypes'

export const INITIAL_STATE = Immutable({
  view: {},
  list: {},
  listParams: {},
  table: {
    page: 1,
  },
  articleParams: {
    sortBy: ''
  },
  directoryParams: {
    inspections: ''
  }
})

function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return state.merge({
        view: state.view.merge({ [action.customName ? action.customName : action.name]: null })
      })

    case actionTypes.LOAD_SUCCESS:
      return state.merge({
        view: state.view.merge({ [action.view.name]: action.view.res })
      })

    case actionTypes.LIST:
      return state.merge({
        listParams: state.listParams.merge({ [action.name]: { query: action.query } })
      })

    case actionTypes.LIST_SUCCESS:
      return state.merge({
        list: state.list.merge({ [action.list.name]: action.list.res })
      })

    case actionTypes.TABLE:
      return state.merge({
        table: action.param
      })
    case actionTypes.HANDLE_CHANGED_ARTICLE_PARAMS:
      return state.merge({
        articleParams: action.param,
        table: {
          page: 1
        }
      })
    case actionTypes.HANDLE_CHANGED_DIRECTORY_PARAMS:
      return state.merge({
        directoryParams: {
          ...state.directoryParams,
          ...action.param
        },
        table: {
          page: 1
        }
      })

    case actionTypes.RESET_STORE:
      return state.replace({
        view: {},
        list: {},
        listParams: {},
        table: {
          page: 1,
          // size: 10,
          // sortBy: '',
          // search: ''
        }
      })

    default:
      return state
  }
}

export default reducer
