import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import menuDropdown
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import Logo from "../../assets/images/logo-sm-light.png";
import Logo from "../../assets/images/logo-light.png";

//i18n
import { withNamespaces } from 'react-i18next';
// import { accessByRole } from '../../utils/accessRight';

class Header extends Component {
  /**
   * Toggle sidebar
   */
  toggleMenu = () => {
    this.props.toggleMenuCallback();
  }

  render() {
    return (
      <React.Fragment>
        <div className="data-topbar">
          <header id="page-topbar">
            <div className="navbar-header">
              <div className="d-flex">

                <div className="navbar-brand-box">
                  <Link to="/" className="logo logo-light">
                    <span className="logo-lg">
                      <img src={Logo} alt="logo" className="img-fluid w-25" />
                    </span>
                  </Link>
                </div>

                <button type="button" onClick={this.toggleMenu} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                  <i className="fa fa-fw fa-bars"></i>
                </button>
              </div>

              <div className="d-flex">
                {/* {accessByRole(['NOTARY', 'ASSISTANT', 'PIC_BCA', 'SPV_PIC_BCA', 'PIC_JADWAL']) &&
                  <NotificationDropdown />
                } */}
                <ProfileMenu />
              </div>
            </div>
          </header>
        </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Header);
