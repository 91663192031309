import React, { Component } from "react";
import Axios from 'axios';
import { Modal, Spinner, Table } from "reactstrap";
import moment from 'moment';
import { connect } from "react-redux";
import { add, list } from '../../store/actions';
import TextField from '../../components/Form/TextField';
import DatePicker from '../../components/Form/DatePicker';
import SelectForm from "../../components/Form/SelectForm";
import withFormik from '../../utils/withFormik';
import UploadFile from '../../components/Form/FormikUploadFile';
import { getUser } from '../../utils/localstorage';
import { ONE_SIGNAL_ID, ONE_SIGNAL_KEY, URL_API } from "../../shared/constants";
import queryString from 'querystringify';
import { toast } from "react-toastify";

// import schemeValidations from './schemeValidationPfic';

const initialValueForm = {
	title: "",
	description: "",
	url: "",
	type: "",
	schedule_at: "",
}

class CreateNotification extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			gotResponse: false,
			baseUrl: process.env.REACT_APP_DOMAIN_API
		}
	}


	handleCreate = () => {
		const { title, description, url, type, schedule_at } = this.props.values
		this.setState({ loading: true })
		const formData = new FormData();
		formData.append('title', title);
		formData.append('description', description);
		formData.append('url', url);
		formData.append('type', 'now');
		formData.append('schedule_at', schedule_at);

		const auth = getUser()
		const post = Axios.post(`${URL_API}api/notifications`, formData,
			{ headers: { "Content-type": `multipart/form-data;boundary=${formData._boundary}`, "Authorization": `Bearer ${auth.token}` } }
		).then(res => {
			console.log(res.data)
			if (res.data.status === 201) {
				this.setState({ loading: false, gotResponse: true })
				toast.success('Berhasil mengirim notifikasi')

				let headers = {
					"Content-Type": "application/json; charset=utf-8",
					"Authorization": `Basic ${ONE_SIGNAL_KEY}`
				};

				// let device_ids = '83a0891b-10ec-4bfa-82ea-d069bb5c09d6'

				let fields = {
					app_id: ONE_SIGNAL_ID,
					contents: { "en": title },
					// include_player_ids: [device_ids],
					included_segments: ['Subscribed Users'],
					small_icon: 'ic_stat_onesignal_default',
					// large_icon: 'https://www.pradini.id/favicon.ico'
				};

				const requestOptions = {
					method: 'POST',
					headers,
					body: JSON.stringify(fields)
				};

				fetch('https://onesignal.com/api/v1/notifications', requestOptions)
					.then(response => {
						console.log(response)
					})
			}
		})
		console.log(post)

	}

	componentDidUpdate(prevProps, prevState) {
		const { resetForm, onClose } = this.props
		if (prevState.gotResponse !== this.state.gotResponse) {
			const { param } = this.props
			const query = queryString.stringify(param)
			this.loadModules(query)
			resetForm()
			onClose()
		}
	}

	loadModules = (param) => {
		const query = queryString.stringify(param)
		this.props.list({
			name: 'notifications',
			customName: 'post',
			query
		})
	}

	render() {
		const {
			data,
			route,
			isOpen,
			onClose,
			values,
			touched,
			errors,
			handleChange,
			handleBlur,
			setFieldValue,
			handleSubmit,
			resetForm } = this.props;
		const { loading } = this.state
		return (
			<Modal
				size="xl"
				isOpen={isOpen}
				centered={true}
			>
				<div className="modal-header">
					<h5 className="modal-title mt-0">Kirim Notifikasi</h5>
					<button
						type="button"
						onClick={() => { resetForm(); onClose() }}
						className="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form className="form-horizontal mt-4">
					<div className="modal-body">
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Nama *</label>
							<div className="col-sm-8">
								<TextField
									name="title"
									required
									value={values.title}
									placeholder="Masukan Nama"
									className="form-control"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.title && errors.title}
								/>
							</div>
							<label className="col-sm-4 col-form-label">Description *</label>
							<div className="col-sm-8 mt-2">
								<TextField
									name="description"
									required
									value={values.description}
									placeholder="Masukan Description"
									className="form-control"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.description && errors.description}
								/>
							</div>
							<label className="col-sm-4 col-form-label">URL *</label>
							<div className="col-sm-8 mt-2">
								<TextField
									name="url"
									required
									value={values.url}
									placeholder="Masukan Nama"
									className="form-control"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.url && errors.url}
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => { resetForm(); onClose() }}
						>
							Batal
						</button>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.handleCreate()}
						>
							{/* Simpan */}
							{loading ? <Spinner size="sm" color="light" /> : 'Kirim'}
						</button>
					</div>
				</form>
			</Modal>
		)
	}
}

const mapStatetoProps = state => {
	return {
		data: state.module.list.type,
		param: state.module.table,
		// loading: state.global.loading,
		gotResponse: state.global.gotResponse,
	};
};

const CreateNotificationFormik = withFormik(
	CreateNotification,
	null,
	initialValueForm, null
)


export default connect(mapStatetoProps, { add, list })(CreateNotificationFormik);
