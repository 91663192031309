import Immutable from 'seamless-immutable';

const ADD = 'ADD';
const ADD_SUCCESS = 'ADD_SUCCESS';
const APPROVE = 'APPROVE';
const APPROVE_SUCCESS = 'APPROVE_SUCCESS';
const BULK_APPROVE = 'BULK_APPROVE';
const BULK_APPROVE_SUCCESS = 'BULK_APPROVE_SUCCESS';
const LOAD = 'LOAD';
const LOAD_SUCCESS = 'LOAD_SUCCESS';
const EDIT = 'EDIT';
const EDIT_SUCCESS = 'EDIT_SUCCESS';
const REMOVE = 'REMOVE';
const REMOVE_SUCCESS = 'REMOVE_SUCCESS';
const BULK_REMOVE = 'BULK_REMOVE';
const BULK_REMOVE_SUCCESS = 'BULK_REMOVE_SUCCESS';
const LIST = 'LIST';
const LIST_SUCCESS = 'LIST_SUCCESS';
const TABLE = 'TABLE';
const EXPORT_EXCEL = 'EXPORT_EXCEL';
const IMPORT_EXCEL = 'IMPORT_EXCEL';
const IMPORT_EXCEL_SUCCESS = 'IMPORT_EXCEL_SUCCESS';
const ORDER_CANCEL = 'ORDER_CANCEL';
const RESET_STORE = 'RESET_STORE';
const HANDLE_CHANGED_ARTICLE_PARAMS = 'HANDLE_CHANGED_ARTICLE_PARAMS';
const HANDLE_CHANGED_DIRECTORY_PARAMS = 'HANDLE_CHANGED_DIRECTORY_PARAMS';

const actionTypes = Immutable({
  ADD,
  ADD_SUCCESS,
  APPROVE,
  APPROVE_SUCCESS,
  BULK_APPROVE,
  BULK_APPROVE_SUCCESS,
  LOAD,
  LOAD_SUCCESS,
  EDIT,
  EDIT_SUCCESS,
  REMOVE,
  REMOVE_SUCCESS,
  BULK_REMOVE,
  BULK_REMOVE_SUCCESS,
  LIST,
  LIST_SUCCESS,
  TABLE,
  EXPORT_EXCEL,
  IMPORT_EXCEL,
  IMPORT_EXCEL_SUCCESS,
  ORDER_CANCEL,
  RESET_STORE,
  HANDLE_CHANGED_ARTICLE_PARAMS,
  HANDLE_CHANGED_DIRECTORY_PARAMS
});

export default actionTypes;