import actionTypes from './actionTypes';

export function add(param) {
  return {
    type: actionTypes.ADD,
    ...param,
  };
}

export function addSuccess(data) {
  return {
    type: actionTypes.ADD_SUCCESS,
    data,
  };
}

export function load(param) {
  return {
    type: actionTypes.LOAD,
    ...param,
  };
}

export function loadSuccess(view) {
  return {
    type: actionTypes.LOAD_SUCCESS,
    view,
  };
}

export function edit(param) {
  return {
    type: actionTypes.EDIT,
    ...param,
  };
}

export function editSuccess(data) {
  return {
    type: actionTypes.EDIT_SUCCESS,
    data,
  };
}

export function remove(param) {
  return {
    type: actionTypes.REMOVE,
    ...param,
  };
}

export function removeSuccess(data) {
  return {
    type: actionTypes.REMOVE_SUCCESS,
    data,
  };
}

export function orderCancel(param) {
  return {
    type: actionTypes.ORDER_CANCEL,
    ...param,
  };
}

export function bulkRemove(param) {
  return {
    type: actionTypes.BULK_REMOVE,
    ...param,
  };
}

export function bulkRemoveSuccess(data) {
  return {
    type: actionTypes.BULK_REMOVE_SUCCESS,
    data,
  };
}

export function approve(param) {
  return {
    type: actionTypes.APPROVE,
    ...param,
  };
}

export function approveSuccess(data) {
  return {
    type: actionTypes.APPROVE_SUCCESS,
    data,
  };
}

export function bulkApprove(param) {
  return {
    type: actionTypes.BULK_APPROVE,
    ...param,
  };
}

export function bulkApproveSuccess(data) {
  return {
    type: actionTypes.BULK_APPROVE_SUCCESS,
    data,
  };
}

export function list(param) {
  return {
    type: actionTypes.LIST,
    ...param,
  };
}

export function setArticleParams(param) {
  return {
    type: actionTypes.HANDLE_CHANGED_ARTICLE_PARAMS,
    param,
  };
}

export function setDirectoryParams(param) {
  return {
    type: actionTypes.HANDLE_CHANGED_DIRECTORY_PARAMS,
    param,
  };
}

export function listSuccess(data) {
  return {
    type: actionTypes.LIST_SUCCESS,
    list: data,
  };
}

export function table(param) {
  return {
    type: actionTypes.TABLE,
    param,
  };
}

export function exportExcel(param) {
  return {
    type: actionTypes.EXPORT_EXCEL,
    ...param
  };
}

export function importExcel({ param, file } ) {
  return {
    type: actionTypes.IMPORT_EXCEL,
    file,
    ...param,
  };
}

export function importExcelSucess(data) {
  return {
    type: actionTypes.IMPORT_EXCEL_SUCCESS,
    data,
  }
}

export const resetStore = () => {
  return {
    type: actionTypes.RESET_STORE
  }
}