import React from 'react';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css"

const Calendar = ({
  error,
  onChange,
  className,
  selected,
  ...props
}) => {
  return (
    <div>
      <DatePicker
        selected={selected}
        onChange={onChange}
        className={error ? `${className} is-invalid` : className}
        {...props}
      />
      {error &&
        <div style={{ margin: '4px 0px 0px', color: '#F46A6A', fontSize: '10.4px' }}>
          {error}
        </div>
      }
    </div>
  )
}

Calendar.defaultProps = {
  error: '',
  className: '',
};

export default Calendar;