import actionTypes from './actionTypes';

export const notif = (data) => {
  return {
    type: actionTypes.NOTIF,
    data
  }
}

export const notifClose = () => {
  return {
    type: actionTypes.NOTIF_CLOSE
  }
}

export const loading = (param) => {
  return {
    type: actionTypes.LOADING,
    param
  }
}

export const captcha = (data) => {
  return {
    type: actionTypes.CAPTCHA,
    data
  }
}

export const loadingTable = (param) => {
  return {
    type: actionTypes.LOADING_TABLE,
    param
  }
}

export const confirmForm = (param) => {
  return {
    type: actionTypes.CONFIRM_FORM,
    param
  }
}

export const gotResponse = (param) => {
  return {
    type: actionTypes.GOT_RESPONSE,
    param
  }
}