import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import {
  changeSidebarType
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      isMobile: false
    };
  }

  capitalizeFirstLetter = string => {
    var split = string.split("/")
    var result = split[1].split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
    if (split.length > 2) {
      if (split[2] === ":id") {
        result = `${split[1].charAt(0).toUpperCase() + split[1].slice(1)} - Detail`
      } else {
        let child = split[2].split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
        result = `${split[1].charAt(0).toUpperCase() + split[1].slice(1)} - ${child}`
      }
    }
    return result
  };

  componentDidMount() {

    if (this.props.isPreloader === true) {
      document.getElementById('preloader').style.display = "block";
      document.getElementById('status').style.display = "block";

      setTimeout(function () {

        document.getElementById('preloader').style.display = "none";
        document.getElementById('status').style.display = "none";

      }, 2500);
    }
    else {
      document.getElementById('preloader').style.display = "none";
      document.getElementById('status').style.display = "none";
    }

    // Scroll Top to 0
    window.scrollTo(0, 0);
    let currentage = this.capitalizeFirstLetter(this.props.match.path);
    document.title = `${currentage} | Pradini`;

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType);
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props
    if (prevProps.match !== this.props.match) {
      if (match.params.id) {
        document.title = `${this.capitalizeFirstLetter(this.props.match.path)} | Pradini`
      } else {
        document.title = `${this.capitalizeFirstLetter(this.props.match.path)} | Pradini`
      }
    }
  }

  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile);
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("default", this.state.isMobile);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>


        <div id="layout-wrapper">
          <Header toggleMenuCallback={this.toggleMenuCallback} />
          <Sidebar
            type={this.props.leftSideBarType}
            isMobile={this.state.isMobile} />
          <div className="main-content">
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStatetoProps = state => {
  return {
    ...state.layout
  };
};
export default withRouter(connect(mapStatetoProps, {
  changeSidebarType
})(Layout));

