import { takeEvery, put, all, call } from 'redux-saga/effects';
import history from '../../utils/history';
import { request } from '../../utils/request';
import { BASE_URL } from '../../utils/urls';
import { getUser, setUser, destroyUser } from '../../utils/localstorage';
import { notif, loading, gotResponse } from '../global/actions';
import actionTypes from './actionTypes';
import {
  failure,
  signUpSuccess,
  forgotPasswordSuccess,
  resetPasswordSuccess,
  changePasswordProfileSuccess
} from './actions';
import { toast } from "react-toastify";

const notifError = msg => notif({ open: true, variant: 'error', message: msg })
const notifSuccess = msg => notif({ open: true, variant: 'success', message: msg })

function * signIn ({ param }) {
  yield put(loading(true))
  const requestURL = `${BASE_URL}api/auth/login`
  const body = JSON.stringify(param)
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body
    })
    console.log(res.me.level)
    if(res.me.level > 10){
      yield put(loading(false))
      yield put(notifError('Maaf anda bukan admin'))
      toast.error('Maaf anda bukan admin')
    } else {
      setUser(res)
      yield put(notifSuccess(res.message || 'Sign-in Success'))
          toast.success('Sign-in berhasil')
      // yield put(captcha(null))
      yield put(loading(false))
      yield call(history.push, '/')
    }
  } catch (err) {
    const res = yield err.response.json()
    yield put(loading(false))
    yield put(notifError(res.message || 'Maaf terjadi kesalahan pada sistem'))
    toast.error(res.message || 'Maaf terjadi kesalahan pada sistem')
  }
}

function * signUp ({ param }) {
  yield put(loading(true))
  const requestURL = `${BASE_URL}/api/auth/signup`
  const body = JSON.stringify(param)
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body
    })
    yield put(notifSuccess(res.message))
    yield put(signUpSuccess(res))
    toast.success('Sign-up berhasil')
    yield call(history.push, '/sign-in')
    yield put(loading(false))
  } catch (err) {
    const res = yield err.response.json()
    yield put(loading(false))
    yield put(signUpSuccess(res))
    yield put(notifError(res.message))
    toast.error(res.message || 'Maaf terjadi kesalahan pada sistem')
  }
}

function * forgotPassword ({ param }) {
  yield put(loading(true))
  const requestURL = `${BASE_URL}/api/auth/password/reset`
  const body = JSON.stringify(param)
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body
    })
    yield put(forgotPasswordSuccess(res))
    toast.success('E-Mail terkait penggantian password akan dikirim. Mohon cek e-mail dan ikuti instruksi pada e-mail.')
    yield put(notifSuccess(res.message || 'E-Mail terkait penggantian password akan dikirim. Mohon cek e-mail dan ikuti instruksi pada e-mail.'))
    yield put(loading(false))
  } catch (err) {
    const res = yield err.response.json()
    yield put(loading(false))
    yield put(notifError(res.message))
    toast.error(res.message || 'Maaf terjadi kesalahan pada sistem')
  }
}

function * resetPassword ({ param }) {
  yield put(loading(true))
  const requestURL = `${BASE_URL}/api/auth/password/change`
  const body = JSON.stringify(param.data)
  const auth = getUser()
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      },
      body
    })
    yield put(notifSuccess('Reset Password Success'))
    toast.success('Berhasil mengganti password')
    yield put(resetPasswordSuccess(res))
    yield put(loading(false))
    yield call(history.push, '/sign-in')
  } catch (err) {
    const res = yield err.response.json()
    yield put(loading(false))
    yield put(resetPasswordSuccess(res))
    yield put(notifError(res.message))
    toast.error(res.message || 'Maaf terjadi kesalahan pada sistem')
  }
}

function * changePasswordProfile ({ param }) {
  yield put(loading(true))
  yield put(gotResponse(false))
  const requestURL = `${BASE_URL}/api/profile/changePassword?${param.query || ''}`
  const body = JSON.stringify(param.data)
  const auth = getUser()
  try {
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      },
      body
    })
    yield put(notifSuccess('Reset Password Success'))
    toast.success('Berhasil mengganti password')
    yield put(changePasswordProfileSuccess(res))
    yield put(loading(false))
    yield put(gotResponse(true))
    if (param.isAfterLogin) {
      destroyUser()
      yield call(history.push, '/sign-in')
    }
  } catch (err) {
    const res = yield err.response.json()
    yield put(loading(false))
    yield put(gotResponse(true))
    yield put(changePasswordProfileSuccess(res))
    yield put(notifError(res.message))
    toast.error(res.message || 'Maaf terjadi kesalahan pada sistem')
  }
}

function * activation ({ param, name, id }) {
  yield put(loading(true))
  const requestURL = `${BASE_URL}/api/${name}/${id}/activate`
  try {
    const auth = getUser();
    const res = yield call(request, requestURL, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}` }
    })
    yield put(notifSuccess(res.message))
    yield call(history.push, `/${name}`)
    yield put(loading(false))
    console.log(res);
  } catch (err) {
    const res = yield err.response.json()
    yield put(loading(false))
    if (err.message) {
      yield put(notifError(err.message))
    } else {
      yield put(notifError(res.message))
    }
  }
}

// function * activation ({ param }) {
//   yield put(loading(true))
//   const requestURL = `${BASE_URL}/api/auth/activation/${param.token}`
//   try {
//     const res = yield call(request, requestURL, {
//       method: 'GET',
//       headers: { 'Content-Type': 'application/json' }
//     })
//     yield put(notifSuccess(res.message))
//     yield call(history.push, '/sign-in')
//     yield put(loading(false))
//   } catch (err) {
//     const res = yield err.response.json()
//     yield put(loading(false))
//     if (err.message) {
//       yield put(notifError(err.message))
//     } else {
//       yield put(notifError(res.message))
//     }
//   }
// }

function* logout() {
  try {
    destroyUser()
    // yield put(captcha(null))
    history.push('/sign-in');
  } catch (error) {
    yield put(failure(error));
  }
}

function* authSaga() {
  yield all([
    takeEvery(actionTypes.SIGN_IN, signIn),
    takeEvery(actionTypes.SIGN_UP, signUp),
    takeEvery(actionTypes.FORGOT_PASSWORD, forgotPassword),
    takeEvery(actionTypes.RESET_PASSWORD, resetPassword),
    takeEvery(actionTypes.CHANGE_PASSWORD_PROFILE, changePasswordProfile),
    takeEvery(actionTypes.ACTIVATION, activation),
    takeEvery(actionTypes.LOGOUT,logout)
  ]);
}

export default authSaga;