// @flow
import { all, call, fork, takeEvery } from "redux-saga/effects";

import {
	CHANGE_SIDEBAR_TYPE,
} from "./actionTypes";

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
	if (document.body) document.body.setAttribute(attribute, value);
	return true;
}


/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = "toggle") {
	switch (action) {
		case "add":
			if (document.body) document.body.classList.add(cssClass);
			break;
		case "remove":
			if (document.body) document.body.classList.remove(cssClass);
			break;
		default:
			if (document.body) document.body.classList.toggle(cssClass);
			break;
	}

	return true;
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }) {
	try {
		switch (sidebarType) {
			case "condensed":
				yield call(manageBodyClass, "sidebar-enable", "add");
				if (!isMobile) yield call(manageBodyClass, "vertical-collpsed", "add");
				break;
			default:
				yield call(changeBodyAttribute, "data-sidebar-size", "");
				yield call(manageBodyClass, "sidebar-enable", "remove");
				if (!isMobile) yield call(manageBodyClass, "vertical-collpsed", "remove");
				break;
		}
	} catch (error) { }
}

/**
 * Watchers
 */

export function* watchChangeLeftSidebarType() {
	yield takeEvery(CHANGE_SIDEBAR_TYPE, changeLeftSidebarType);
}

function* LayoutSaga() {
	yield all([
		fork(watchChangeLeftSidebarType)
	]);
}

export default LayoutSaga;
