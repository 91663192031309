import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import queryString from 'querystringify';
import Create from './Create';
import Update from './Edit';
import Delete from './Delete';
import { list } from '../../store/actions';
import Tables from '../../components/DynamicTables';

const columns = [
  {
    Header:'Judul / Nama',
    accessor: 'title'
  },
  {
    Header: 'Deskripsi',
    accessor: 'description'
  },
  {
    Header: 'Waktu Mulai',
    accessor: 'start_at'
  }
]

class Artikel extends Component {
  state = {
    isCreate: false,
    isUpdate: false,
    isDelete: false,
    originalData: []
  }

  loadModules = (param) => {
    const query = queryString.stringify(param)
    this.props.list({
      name: 'forum/events',
      customName: 'post',
      query
    })
  }

  componentDidMount() {
    const { query } = this.props
    this.loadModules(query)
  }

  componentDidUpdate(prevProps) {
    const { query,data } = this.props
    // query.page + 1
    const page = query + 1
    if (prevProps.query !== this.props.query) {
      this.loadModules(query)
    }
  }

  // handleView = (data) => {
  //   const { history } = this.props
  //   const { uuid } = data.original
  //   history.push(`/post/${uuid}`)
  // }
  handleDelete = (data, isDelete) => {
    let originalData = data && data.original

    this.setState({
      isDelete,
      originalData: originalData
    })
  }
  handleCreate = (isCreate) => {
    this.setState({
      isCreate,
    })
  }
  handleUpdate = (data,isUpdate) => {
    let originalData = data && data.original

    this.setState({
      isUpdate,
      originalData: originalData
    })
  }
  render() {
    const { data } = this.props
    let result = (data && data.data.data && data.data.data) || []
    console.log(result)
    const { isCreate, isUpdate, isDelete, originalData } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Tables
              withFilter={{ placeHolder: 'Cari Event'}}
              withSelectRow
              withPagination
              title="Event"
              name="tasklist" // following the name or customName in this.props.list
              columns={columns}
              withCreate={(isCreate) => this.handleCreate(isCreate)}
              data={result}
              actions={[
                { label: 'Update', color: 'light', action: (data, isUpdate) => this.handleUpdate(data,isUpdate) },
                { label: 'Delete', color: 'danger', action: (data, isDelete) => this.handleDelete(data,isDelete) }
              ]}
            />
            <Create 
              isOpen={isCreate}
              onClose={()=> this.setState({ isCreate: false})}
            />
            <Update
              isOpen={isUpdate}
              onClose={() => this.setState({ isUpdate: false })}
              original={originalData}
            />
            <Delete
              isOpen={isDelete}
              onClose={() => this.setState({ isDelete: false })}
              original={originalData}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    data: state.module.list.post,
    query: state.module.table
  };
};

export default connect(mapStatetoProps, { list })(Artikel);
