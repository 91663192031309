import React, { Component } from "react";
import Axios from 'axios';
import { Modal, Spinner, Table } from "reactstrap";
import moment from 'moment';
import { connect } from "react-redux";
import { add, list } from '../../store/actions';
import TextField from '../../components/Form/TextField';
import DatePicker from '../../components/Form/DatePicker';
import SelectForm from "../../components/Form/SelectForm";
import withFormik from '../../utils/withFormik';
import UploadFile from '../../components/Form/FormikUploadFile';
import { getUser } from '../../utils/localstorage';
import { URL_API } from "../../shared/constants";
import queryString from 'querystringify';
import { toast } from "react-toastify";

// import schemeValidations from './schemeValidationPfic';

const initialValueForm = {
	title: '',
	description: '',
	start_at: ''
}

// const handleFormSubmit = (payload, ctx) => {
// 	const { title, desc, thumbnail, content, tags } = payload
// 	// ctx.props.add({
// 	// 	name: `posts`,
// 	// 	data: {
// 	// 		type: 'article',
// 	// 		title: title,
// 	// 		description: desc,
// 	// 		  thumbnail: thumbnail,
// 	// 		content: content,
// 	// 		tags: tags,
// 	// 		parent_id: 1,
// 	// 	},
// 	// });
// 	// Axios.post()
// }

class CreateArticle extends Component {
	constructor() {
		super();
		this.state = {
			file: '',
			loading: false,
			gotResponse: false,
			baseUrl: URL_API,
			// baseUrl: process.env.REACT_APP_DOMAIN_API
		}

		this.fileSelect = this.fileSelect.bind(this);
	}

	fileSelect(e) {
		this.setState({ file: e.target.files[0] })
	}

	loadModules = (param) => {
		const query = queryString.stringify(param)
		this.props.list({
			name: 'forum/events',
			customName: 'post',
			query
		})
	}

	handleCreate = () => {
		const { title, description, start_at, city, tags, status, type, parent_id, phone } = this.props.values
		console.log(this.state.file)
		this.setState({ loading: true })
		const formData = new FormData();
		formData.append('title', title);
		formData.append('thumbnail', this.state.file)
		formData.append('description', description);
		formData.append('start_at', start_at);
		// formData.append('status', status);

		const auth = getUser()
		const post = Axios.post(`${this.state.baseUrl}api/forum/events`, formData,
			{ headers: { "Content-type": `multipart/form-data;boundary=${formData._boundary}`, "Authorization": `Bearer ${auth.token}` } }
		).then(res => {
			console.log(res.data)
			if (res.data.status === 201) {
				this.setState({ loading: false, gotResponse: true })
				toast.success('Berhasil menambah event')
			}
		})
		console.log(post)

	}

	componentDidUpdate(prevProps, prevState) {
		const { resetForm, onClose } = this.props
		if (prevState.gotResponse !== this.state.gotResponse) {
			const { param } = this.props
			const query = queryString.stringify(param)
			this.loadModules(query)
			resetForm()
			onClose()
		}
	}

	render() {
		const {
			data,
			route,
			isOpen,
			onClose,
			values,
			touched,
			errors,
			handleChange,
			handleBlur,
			setFieldValue,
			handleSubmit,
			resetForm } = this.props;
		const { loading } = this.state
		return (
			<Modal
				size="xl"
				isOpen={isOpen}
				centered={true}
			>
				<div className="modal-header">
					<h5 className="modal-title mt-0">Tambah Forum</h5>
					<button
						type="button"
						onClick={() => { resetForm(); onClose() }}
						className="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form className="form-horizontal mt-4">
					<div className="modal-body">
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Judul *</label>
							<div className="col-sm-8">
								<TextField
									name="title"
									required
									value={values.title}
									placeholder="Masukan Nama"
									className="form-control"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.title && errors.title}
								/>
							</div>
							<label className="col-sm-4 mt-2 col-form-label">Waktu Mulai *</label>
							<div className="col-sm-8 mt-2">
								<TextField
									name="start_at"
									required
									value={values.start_at}
									placeholder="Masukan Waktu Mulai"
									className="form-control"
									type="datetime-local"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.start_at && errors.start_at}
								/>
							</div>
							<label className="col-sm-4 mt-2 col-form-label">Description *</label>
							<div className="col-sm-8 mt-2">
								<TextField
									name="description"
									required
									value={values.description}
									placeholder="Masukan Description"
									className="form-control"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.description && errors.description}
								/>
							</div>
							<label className="col-sm-4 col-form-label">Thumbnail *</label>
							<div className="col-sm-8 mt-2">
								<input type="file" onChange={this.fileSelect} />
							</div>
							{/* <label className="col-sm-4 col-form-label">Status *</label>
							<div className="col-sm-8 mt-2">
								<SelectForm
									name="status"
									required
									type="select"
									value={values.status}
									className="form-control"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.status && errors.status}
								>
									<option value=''>Pilih Status</option>
									<option value="publish">
										publish
									</option>
									<option  value="private">
										private
									</option>
								</SelectForm>
							</div> */}
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => { resetForm(); onClose() }}
						>
							Batal
						</button>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.handleCreate()}
						>
							{/* Simpan */}
							{loading ? <Spinner size="sm" color="light" /> : 'Simpan'}
						</button>
					</div>
				</form>
			</Modal>
		)
	}
}

const mapStatetoProps = state => {
	return {
		data: state.module.list.type,
		param: state.module.table,
		// loading: state.global.loading,
		gotResponse: state.global.gotResponse,
	};
};

const CreateArticleFormik = withFormik(
	CreateArticle,
	null,
	initialValueForm, null
)


export default connect(mapStatetoProps, { add, list })(CreateArticleFormik);
