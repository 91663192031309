import { withFormik } from 'formik';

const Formik = (Component, validationSchema, initialValues, handleSubmit, displayName) => {
  let mapPropsToValues = null;
  if (typeof initialValues === 'function') {
    mapPropsToValues = initialValues;
  } else {
    mapPropsToValues = () => initialValues;
  }
  const formikEnhancer = withFormik({
    validationSchema,
    mapPropsToValues,
    handleSubmit,
    displayName: displayName || Component.displayName || `${Component.name}WithFormikForm`,
    enableReinitialize: true
  });
  return formikEnhancer(Component);
};

export default Formik;
