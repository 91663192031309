export const directoryInspections = [
    { value: 'Mata', label: 'Mata' },
    { value: 'Telinga', label: 'Telinga' },
    { value: 'Jantung', label: 'Jantung' },
    { value: 'Tiroid', label: 'Tiroid' },
    { value: 'Kepala', label: 'Kepala' },
    { value: 'Anemia', label: 'Anemia' },
]

export  const sortDataArticle = [
    { value: 'old', label: 'Terlama', type: 'sort' },
    { value: 'new', label: 'Terbaru', type: 'sort' },
];

export const articleCategory = [
    {
        value: 'nutrisi', label: 'Nutrisi', type: 'category', user: 'doctor',
    },
    {
        value: 'grafik', label: 'Grafik Pertumbuhan', type: 'category', user: 'doctor',
    },
    {
        value: 'screening', label: 'Skrining Kesehatan', type: 'category', user: 'doctor',
    },
    {
        value: 'article', label: 'Artikel', type: 'category', user: 'general',
    },
    {
        value: 'problem', label: 'Ceklis', type: 'category', user: 'general',
    },
    {
        value: 'family', label: 'Edukasi Keluarga', type: 'category', user: 'general',
    },

]; 