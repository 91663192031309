import { all, call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import history from '../../utils/history';
import { request, requestBlob } from '../..//utils/request';
import { BASE_URL } from '../../utils/urls';
import { getUser } from '../../utils/localstorage';
import FileSaver from 'file-saver';
import { toast } from "react-toastify";

import actionTypes from './actionTypes';
import {
  notif,
  loading,
  loadingTable,
  addSuccess,
  loadSuccess,
  editSuccess,
  removeSuccess,
  bulkRemoveSuccess,
  approveSuccess,
  bulkApproveSuccess,
  listSuccess,
  list as loadTable,
  gotResponse
} from '../actions';

const notifError = msg => notif({ open: true, variant: 'error', message: msg })
const notifSuccess = msg => notif({ open: true, variant: 'success', message: msg })

function* add({ name, data, onSuccess, customMessage, withoutSuccessNotif, withoutErrorNotif }) {
  yield put(loading(true))
  yield put(gotResponse(false))
  const requestURL = `${BASE_URL}api/${name}`
  // const body = JSON.stringify(data)
  try {
    const auth = getUser()
    const body = new FormData() 
    body.append('thumbnail', data.thumbnail)
    body.append('title',data.title)
    body.append('type',data.type)
    body.append('content',data.content)
    body.append('tags',data.tags)
    body.append('description',data.description)
    body.append('parent_id',1)
    body.append('status',"publish")

    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth.token}`
      },
      body
    })
    if (!withoutSuccessNotif) {
      yield put(notifSuccess(res.message || customMessage || 'Create success'))
      toast.success(customMessage || 'Tambah berhasil')
    }
    yield put(addSuccess(res))
    yield put(loading(false))
    yield put(gotResponse(true))
    if (onSuccess) {
      onSuccess(res)
    }
  } catch (err) {
    const res = yield err.response.json()
    yield put(loading(false))
    yield put(gotResponse(true))
    if (!withoutErrorNotif) {
      yield put(notifError(res.message))
      toast.error(res.message)
    }
  }
}

function* load({ name, id, customName, query }) {
  yield put(loading(true))
  const requestURL = `${BASE_URL}/api/${name}/${id || ''}?${query || ''}`
  try {
    const auth = getUser()
    const res = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      }
    })
    yield put(loadSuccess({ name: customName || name, res }))
    yield put(loading(false))
  } catch (err) {
    const res = yield err.response.json()
    yield put(loading(false))
    yield put(notifError(res.message))
  }
}

function* edit({ name, id, data, onSuccess, customMessage, withoutSuccessNotif, withoutErrorNotif }) {
  yield put(loading(true))
  yield put(gotResponse(false))
  const requestURL = `${BASE_URL}/api/${name}/${id || ''}`
  const body = JSON.stringify(data)
  try {
    const auth = getUser()
    const res = yield call(request, requestURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      },
      body
    })
    if (!withoutSuccessNotif) {
      yield put(notifSuccess(res.message || 'Update Success'))
      toast.success(customMessage || `Ubah berhasil`)
    }
    yield put(editSuccess(res))
    yield put(loading(false))
    yield put(gotResponse(true))
    if (onSuccess) {
      onSuccess(res)
    }
  } catch (err) {
    const res = yield err.response.json()
    yield put(loading(false))
    yield put(gotResponse(true))
    if (!withoutErrorNotif) {
      yield put(notifError(res.message))
      toast.error(res.message)
    }
  }
}

function* orderCancel({ name, customMessage, withoutSuccessNotif, withoutErrorNotif, onSuccess }) {
  yield put(loading(true))
  yield put(gotResponse(false))
  const requestURL = `${BASE_URL}/api/${name}`
  try {
    const auth = getUser()
    const res = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      }
    })
    if (!withoutSuccessNotif) {
      yield put(notifSuccess(res.message || customMessage || 'Create success'))
      toast.success(customMessage || 'Delete berhasil')
    }
    yield put(loading(false))
    yield put(gotResponse(true))
  } catch (err) {
    yield put(loading(false))
    const res = yield err.response.json()
    yield put(loading(false))
    yield put(gotResponse(true))
    if (!withoutErrorNotif) {
      yield put(notifError(res.message))
      toast.error(res.message)
    }
  }
}

function* remove({ name, id, customMessage, withoutSuccessNotif = false, withoutErrorNotif = false }) {
  yield put(loading(true))
  yield put(gotResponse(false))
  const requestURL = `${BASE_URL}api/${name}/${id}`
  console.log("SAGE DELETE RUN")
  try {
    const auth = getUser()
    const res = yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      }
    })
    if (!withoutSuccessNotif) {
      yield put(notifSuccess(res.message || customMessage || 'Delete success'))
      toast.success(customMessage || `Hapus berhasil`)
    }
    yield call(list({
      name: 'posts',
      customName: 'post',
      query: ''
    }))
    yield put(removeSuccess(res))
    yield put(loading(false))
    yield put(gotResponse(true))
    
  } catch (err) {
    // const res = yield err.response.json()
    // if(err.include("(request.js:12)")){
      yield put(loading(false))
      yield put(gotResponse(true))
    // }
    if (!withoutErrorNotif) {
      // yield put(notifError(res.message))
      // toast.error(res.message)
    }
  }
}

function* bulkRemove({ name, data }) {
  yield put(loading(true))
  const requestURL = `${BASE_URL}/api/${name}/bulk-delete`
  const body = JSON.stringify({ ids: data })
  const listParams = yield select(state => state.module.listParams)
  try {
    const auth = getUser()
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${auth.token}`
      },
      body
    })
    yield put(loading(false))
    yield put(notifSuccess(res.message))
    yield put(bulkRemoveSuccess(res))
    yield put(loadTable({
      name,
      query: listParams[name].query
    }))
  } catch (err) {
    yield put(loading(false))
    const res = yield err.response.json()
    if (res.message) {
      yield put(notifError(res.message))
    } else {
      yield put(notifError(err.message))
    }
  }
}

function* approve({ name, id, status = 'approved', disable, withoutErrorNotif }) {
  yield put(loading(true))
  const requestURL = `${BASE_URL}api/${name}/${id}/activate`
  try {
    const auth = getUser();
    const res = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      },
    })
    yield put(approveSuccess(res))
    yield put(loading(false))
    yield put(gotResponse(true))    
  } catch (err) {
    // console.log(err.include("(request.js:12)"))
    // const res = yield err.response.json()
    // if(err.include("(request.js:12)")){
      yield put(loading(false))
      yield put(gotResponse(true))
      console.log(err)
    // }
    if (!withoutErrorNotif) {
      // yield put(notifError(err.message))
      disable = true;
      toast.success("Dokter berhasil di approve")
    }
  }
}

function* bulkApprove({ name, data, status = 'approved' }) {
  yield put(loading(true))
  const requestURL = `${BASE_URL}/api/${name}/bulk-approve`
  const body = JSON.stringify({ ids: data, status })
  const listParams = yield select(state => state.module.listParams)
  try {
    const auth = JSON.parse(getUser())
    const res = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${auth.token}`
      },
      body
    })
    yield put(notifSuccess(res.message))
    yield put(loading(false))
    yield put(bulkApproveSuccess(res))
    yield put(loadTable({
      name,
      query: listParams[name].query
    }))
  } catch (err) {
    yield put(loading(false))
    const res = yield err.response.json()
    if (res.message) {
      yield put(notifError(res.message))
    } else {
      yield put(notifError(err.message))
    }
  }
}

function* list({ name, query, customName,withType , withoutErrorNotif }) {
  yield put(loadingTable(true))
  const requestURL = `${BASE_URL}api/${name}${withType || '?'}${query || ''}`
  console.log(requestURL);
  try {
    const auth = getUser()
    const res = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      }
    })
    yield put(listSuccess({ name: customName || name, res }))
    yield put(loadingTable(false))
  } catch (err) {
    const res = yield err.response.json()
    yield put(loadingTable(false))
    if (!withoutErrorNotif) {
      notifError(res.message)
      toast.error(res.message)
    }
  }
}

function* exportExcel({ module, name, query, url, onSuccess, customMessage }) {
  yield put(loading(true));
  const requestURL = `${BASE_URL}/api/${name}?${query || ''}`
  try {
    const auth = getUser();
    const data = yield call(requestBlob, requestURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${auth.token}`
      }
    });
    FileSaver.saveAs(data, `${module || url}.xlsx`);
    yield put(loading(false))
  } catch (err) {
    const res = yield err.response.json()
    yield put(loading(false));
    yield put(notifError(res.message));
    toast.error(res.message)
  }
}

function* importExcel({ module, url, file, onSuccess }) {
  yield put(loading(true));
  let requestURL = `${BASE_URL}/api/registrations/import-excel`;
  const jylo = new FormData();
  jylo.set('newImport', file);
  try {
    const auth = getUser();
    const data = yield call(requestBlob, requestURL, {
      method: "POST",
      headers: {
        Authorization: `JWT ${auth.token}`
      },
      body: jylo,
    });
    yield put(notifSuccess(data.message || 'Import Excel Success'))
    if (onSuccess) {
      onSuccess({ name: module || url })
      yield put(notifSuccess(data.message || 'Import Excel Success'))
    }
  } catch (err) {
    if (onSuccess) {
      onSuccess({ name: module || url })
    }
    yield put(loading(false));
    if (err.message) {
      yield put(notifError(err.message));
    }
  }
}

function* moduleWatcher() {
  yield all([
    takeLatest(actionTypes.ADD, add),
    takeEvery(actionTypes.LOAD, load),
    takeEvery(actionTypes.ORDER_CANCEL, orderCancel),
    takeLatest(actionTypes.EDIT, edit),
    takeLatest(actionTypes.REMOVE, remove),
    takeLatest(actionTypes.BULK_REMOVE, bulkRemove),
    takeLatest(actionTypes.APPROVE, approve),
    takeLatest(actionTypes.BULK_APPROVE, bulkApprove),
    takeEvery(actionTypes.LIST, list),
    takeEvery(actionTypes.EXPORT_EXCEL, exportExcel),
    takeEvery(actionTypes.IMPORT_EXCEL, importExcel),
  ])
}

export default moduleWatcher
